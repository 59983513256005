import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler } from './utils'

/**
 * Get user info
 * @param {string} id
 */
const getUser = async (id) => {
  try {
    const response = await API.get(ENDPOINTS.USER({ id }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

export { getUser }
