import React from 'react'
import PropTypes from 'prop-types'

import style from './index.module.less'
import classNames from 'classnames'

const InputText = (props) => {
  const { disabled, name } = props

  const classNameOptions = {}
  classNameOptions[style['textInput']] = true
  classNameOptions[style['disabled']] = disabled
  const className = classNames(classNameOptions)

  const value = props.value.value
  const onChange = (e) => {
    const newValue = {
      value: e.target.value
    }

    props.onChange({
      target: {
        name,
        value: { ...props.value, ...newValue }
      }
    })
  }

  return (
    <div>
      <input
        className={className}
        name={name}
        value={value}
        onChange={onChange}
        type='text'
        disabled={disabled}
      />
    </div>
  )
}

InputText.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
}

export default React.memo(InputText)
