import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler } from './utils'

/**
 * Get an answer
 * @param {string} answerId: uuid that referring a single answer under the questionnaire
 */
const getAnswer = async ({ answerId }) => {
  try {
    const response = await API.get(ENDPOINTS.ANSWER({ answerId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get all answers under the questionnaire
 * @param {string} questionnaireId: uuid that referring a single questionnaire under the project
 */
const getAnswers = async ({ questionnaireId }) => {
  try {
    const response = await API.get(ENDPOINTS.ANSWERS({ questionnaireId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * create an answer under that questionnaire
 * @param {string} questionnaireId: uuid that referring a single questionnaire under the project
 * @param {object} answer: the structure of the answer comment
 */
const createAnswer = async ({ questionnaireId, answer }) => {
  try {
    const response = await API.post(ENDPOINTS.ANSWERS({ questionnaireId }), answer)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * update an answer
 * @param {string} answerId: uuid that referring a single answer under the questionnaire
 * @param {object} answer: the updated structure of the answer comment
 */
const updateAnswer = async ({ answerId, answer }) => {
  try {
    const response = await API.put(ENDPOINTS.ANSWER({ answerId }), answer, {})
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * delete an answer
 * @param {string} answerId: uuid that referring a single answer under the questionnaire
 */
const deleteAnswer = async ({ answerId }) => {
  try {
    const response = await API.delete(ENDPOINTS.ANSWER({ answerId }), {})
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

export { getAnswer, getAnswers, createAnswer, updateAnswer, deleteAnswer }
