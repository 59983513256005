import React, { useEffect, useReducer, useState } from 'react'
import PropType from 'prop-types'

import Form from './components/form'
import styles from './styles/index.module.less'
import { reducer, init } from './reducer'
import Data from './data'
import { DataContext, WidgetsContext } from './context'
import { FormWrapper } from './styles'
import Question from './components/question'
import * as FORM_CONSTANTS from './constants'

import 'rc-slider/assets/index.css'
import 'rc-time-picker/assets/index.css'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import 'react-virtualized/styles.css'

const FormData = Data

const FormEngine = (props) => {
  const {
    answers,
    currentSectionIndex,
    disabled,
    onAnswersChange,
    onProgressChange,
    onQuestionComponentsChange,
    questionSet,
    vocabularies
  } = props
  const [state, dispatch] = useReducer(reducer, props, init)
  const [data] = useState(
    new Data({
      answers,
      dispatch,
      onAnswersChange,
      onProgressChange,
      onQuestionComponentsChange,
      questionSet,
      vocabularies
    })
  )

  useEffect(() => {
    data.generateData({ answers })
  }, [answers, data])

  return (
    <WidgetsContext.Provider value={state.widgets}>
      <DataContext.Provider value={data}>
        <FormWrapper className={styles['dynamic-form']}>
          <Form
            answers={answers}
            questionSet={questionSet}
            vocabularies={vocabularies}
            index={currentSectionIndex}
            validations={state.validations}
            disabled={disabled}
          />
        </FormWrapper>
      </DataContext.Provider>
    </WidgetsContext.Provider>
  )
}

FormEngine.defaultProps = {
  answers: {},
  currentSectionIndex: 0,
  vocabularies: []
}

FormEngine.propTypes = {
  answers: PropType.object,
  currentSectionIndex: PropType.number,
  disabled: PropType.bool,
  enableTimer: PropType.bool,
  name: PropType.string,
  onAnswersChange: PropType.func.isRequired,
  onProgressChange: PropType.func,
  onQuestionComponentsChange: PropType.func,
  questionSet: PropType.object,
  typeWidgetMapping: PropType.object,
  vocabularies: PropType.array,
  widgets: PropType.object
}

export default FormEngine

export { FormData, Question, reducer, init, FORM_CONSTANTS }
