import { QUESTION_TYPE, INPUT_TYPE } from '../constants'

// const validateAnswer = (validation, answer) => {
//   const { value } = answer
//   const { isRequired, maxLength, minLength, number, match } = validation
//   const isMaxLengthRequired = maxLength.required
//   const maxLengthValue = maxLength.value && maxLength.value !== '' ? Number(maxLength.value) : null
//   const isMinLengthRequired = minLength.required
//   const minLengthValue = minLength.value && minLength.value !== '' ? Number(minLength.value) : null
//   const isNumberValidationRequired = number.required
//   const minNumber = number.minValue && number.minValue !== '' ? Number(number.minValue) : null
//   const maxNumber = number.maxValue && number.maxValue !== '' ? Number(number.maxValue) : null
//   let error
//   if (isRequired && (value === '' || (Array.isArray(value) && value.length === 0))) {
//     error = ' IS A REQUIRED FIELD.'
//   } else if (!(value === '' || (Array.isArray(value) && value.length === 0))) {
//     if (isMaxLengthRequired && maxLengthValue && value.length > maxLengthValue) {
//       error = ` MAX LENGTH IS ${maxLengthValue}`
//     } else if (isMinLengthRequired && minLengthValue && value.length < minLengthValue) {
//       error = ` MIN LENGTH IS ${minLengthValue}`
//     } else if (isNumberValidationRequired && isNaN(Number(value))) {
//       error = ' SHOULD BE NUMBER'
//     } else if (isNumberValidationRequired && minNumber !== null && Number(value) < minNumber) {
//       error = ` SHOULD BE GREATER THAN OR EQUAL TO ${minNumber}`
//     } else if (isNumberValidationRequired && maxNumber !== null && Number(value) > maxNumber) {
//       error = ` SHOULD BE LESS THAN OR EQUAL TO ${maxNumber}`
//     } else if (match && match.required) {
//       const regex = new RegExp(match.value)
//       if (!value.match(regex)) {
//         error = match.errorMessage
//       }
//     }
//   }
//   return error
// }

/**
 * Initialize question to ''
 *            question set to {}
 *            repeated question set to []
 * @param {Object} questionSetInAnswer answer object of question set
 * @param {Object} q current question
 */
const initialAnswer = (questionSetInAnswer, q) => {
  if (q.type === QUESTION_TYPE.QUESTION) {
    if (typeof questionSetInAnswer[q.variable] === 'undefined') {
      if (q.inputType === INPUT_TYPE.CHECKBOX) {
        questionSetInAnswer[q.variable] = {
          value: []
        }
      } else {
        questionSetInAnswer[q.variable] = {
          value: ''
        }
      }
    } else if (
      typeof questionSetInAnswer[q.variable] === 'string' &&
      q.inputType === INPUT_TYPE.CHECKBOX
    ) {
      // Handle the situation that admin change the input type from single slection to mulitple choice
      questionSetInAnswer[q.variable] = {
        value: []
      }
    }
  } else if (q.type === QUESTION_TYPE.QUESTION_SET) {
    if (!questionSetInAnswer[q.name]) {
      questionSetInAnswer[q.name] = {}
    }
  }
}

/**
 * Get the answer in object through the path
 * @param {Array} path: the path to the the answer.
 * @param {String} variable: the name of the variable.
 * e.g ['population', 1, 'result'] leads to answer[population][1][result]
 */
const getAnswerWithPath = function (path, variable, answers) {
  let answer = null
  try {
    answer = path.reduce((o, i) => o[i], answers)
  } catch (e) {
    return {
      value: ''
    }
  }
  return answer ? (answer[variable] ? answer[variable] : { value: '' }) : { value: '' }
}

/**
 * Reset input answer to empty string
 * @param {*} name answer variable
 * @param {*} path answer path
 */
// const resetAnswer = (name, path) => {
//   const _self = this

//   if (_.isEmpty(_self.props.answers)) {
//     return [_self.props.answers, false]
//   }
//   let answers = _.cloneDeep(_self.props.answers)
//   const answer = _.get(answers, path)
//   const isChanged = answer && answer[name].value !== ''
//   if (isChanged) {
//     answers = _.set(answers, [...path, name], { ...answer[name], value: '' })
//     _self.props.onAnswersChange(answers)
//   }
// }

export { initialAnswer, getAnswerWithPath }
