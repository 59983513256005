import Immutable from 'immutable'

import {
  CREATE_TASK_COMMENT,
  CREATE_TASK_COMMENT_FULFILLED,
  CREATE_TASK_COMMENT_REJECTED,
  DISLIKE_TASK_COMMENT,
  DISLIKE_TASK_COMMENT_FULFILLED,
  DISLIKE_TASK_COMMENT_REJECTED,
  GET_TASK_COMMENTS,
  GET_TASK_COMMENTS_FULFILLED,
  GET_TASK_COMMENTS_REJECTED,
  LIKE_TASK_COMMENT,
  LIKE_TASK_COMMENT_FULFILLED,
  LIKE_TASK_COMMENT_REJECTED
} from '@/store/types'
import { replaceArrayItem } from '@/services/utils/array'
import { STORE } from '@/constants/strings'

const DEFAULT_STATE = {
  [STORE.DATA]: null,
  [STORE.ERROR]: null,
  [STORE.IS_PENDING]: false
}

const initialState = new Immutable.Map({
  ...DEFAULT_STATE,
  [STORE.CACHED_USERS]: []
})

export default {
  initialState,
  handlers: {
    // [GET_TASK_COMMENT]: (state) =>
    //   state.merge({
    //     [STORE.IS_PENDING]: true
    //   }),
    // [GET_TASK_COMMENT_FULFILLED]: (state, action) =>
    //   state.merge({
    //     [STORE.IS_PENDING]: false,
    //     [STORE.DATA]: action.payload,
    //     [STORE.ERROR]: null
    //   }),
    // [GET_TASK_COMMENT_REJECTED]: (state, action) =>
    //   state.merge({
    //     [STORE.IS_PENDING]: false,
    //     [STORE.DATA]: null,
    //     [STORE.ERROR]: action.payload
    //   }),
    [GET_TASK_COMMENTS]: (state) => state.merge({ ...DEFAULT_STATE, [STORE.IS_PENDING]: true }),
    [GET_TASK_COMMENTS_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.IS_PENDING]: false,
        [STORE.DATA]: action.payload.comments,
        [STORE.ERROR]: null,
        [STORE.CACHED_USERS]: action.payload.users
      }),
    [GET_TASK_COMMENTS_REJECTED]: (state, action) =>
      state.merge({
        [STORE.IS_PENDING]: false,
        [STORE.DATA]: null,
        [STORE.ERROR]: action.payload
      }),
    [CREATE_TASK_COMMENT]: (state) => state,
    [CREATE_TASK_COMMENT_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.DATA]: state.get(STORE.DATA) ? [...state.get(STORE.DATA), action.payload] : null
      }),
    [CREATE_TASK_COMMENT_REJECTED]: (state, action) =>
      state.merge({
        [STORE.ERROR]: action.payload
      }),
    // [UPDATE_TASK_COMMENT]: (state) =>
    //   state.merge({
    //     [STORE.TASK_COMMENTS]: { ...state.get(STORE.TASK_COMMENTS), [STORE.IS_PENDING]: true }
    //   }),
    // [UPDATE_TASK_COMMENT_FULFILLED]: (state, action) =>
    //   state.merge({
    //     [STORE.TASK_COMMENTS]: {
    //       [STORE.DATA]: replaceArrayItem(
    //         action.payload,
    //         state.get(STORE.TASK_COMMENTS)[STORE.DATA]
    //       ),
    //       [STORE.IS_PENDING]: false,
    //       [STORE.ERROR]: null
    //     }
    //   }),
    // [UPDATE_TASK_COMMENT_REJECTED]: (state, action) =>
    //   state.merge({
    //     [STORE.TASK_COMMENTS]: {
    //       ...state.get(STORE.TASK_COMMENTS),
    //       [STORE.IS_PENDING]: false,
    //       [STORE.ERROR]: action.payload
    //     }
    //   }),
    // [DELETE_TASK_COMMENT]: (state) =>
    //   state.merge({
    //     [STORE.TASK_COMMENTS]: { ...state.get(STORE.TASK_COMMENTS), [STORE.IS_PENDING]: true }
    //   }),
    // [DELETE_TASK_COMMENT_FULFILLED]: (state, action) =>
    //   state.merge({
    //     [STORE.TASK_COMMENTS]: {
    //       [STORE.DATA]: removeArrayItem(action.payload, state.get(STORE.TASK_COMMENTS)[STORE.DATA]),
    //       [STORE.IS_PENDING]: false,
    //       [STORE.ERROR]: null
    //     }
    //   }),
    // [DELETE_TASK_COMMENT_REJECTED]: (state, action) =>
    //   state.merge({
    //     [STORE.TASK_COMMENTS]: {
    //       ...state.get(STORE.TASK_COMMENTS),
    //       [STORE.IS_PENDING]: false,
    //       [STORE.ERROR]: action.payload
    //     }
    //   }),
    [LIKE_TASK_COMMENT]: (state) => state,
    [LIKE_TASK_COMMENT_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.DATA]: state.get(STORE.DATA)
          ? replaceArrayItem(action.payload, state.get(STORE.DATA))
          : null
      }),
    [LIKE_TASK_COMMENT_REJECTED]: (state, action) =>
      state.merge({
        [STORE.ERROR]: action.payload
      }),

    [DISLIKE_TASK_COMMENT]: (state) => state,
    [DISLIKE_TASK_COMMENT_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.DATA]: state.get(STORE.DATA)
          ? replaceArrayItem(action.payload, state.get(STORE.DATA))
          : null
      }),
    [DISLIKE_TASK_COMMENT_REJECTED]: (state, action) =>
      state.merge({
        [STORE.ERROR]: action.payload
      })
  }
}
