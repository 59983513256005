import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler } from './utils'

/**
 * Get a project
 * @param {string} projectId: uuid that referring a single project
 */
const getProject = async ({ projectId }) => {
  try {
    const response = await API.get(ENDPOINTS.PROJECT({ projectId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get all projects
 */
const getProjects = async () => {
  try {
    const response = await API.get(ENDPOINTS.PROJECTS)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * create a project
 * @param {object} project: the structure of the project
 */
const createProject = async ({ project }) => {
  try {
    const response = await API.post(ENDPOINTS.PROJECTS, project, {})
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * update a project
 * @param {string} projectId: uuid that referring a single project
 * @param {object} project: the updated structure of the project
 */
const updateProject = async ({ projectId, project }) => {
  try {
    const response = await API.put(ENDPOINTS.PROJECT({ projectId }), project, {})
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * delete project
 * @param {string} projectId: uuid that referring a single project
 */
const deleteProject = async ({ projectId }) => {
  try {
    const response = await API.delete(ENDPOINTS.PROJECT({ projectId }), {})
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

export { getProject, getProjects, createProject, updateProject, deleteProject }
