import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import style from './index.module.less'

const Timer = (props) => {
  const { expireInSeconds, expireTime, label, onComplete, onReady } = props

  const defaultIsStarted = expireTime ? true : false
  let defaultCountDown
  let defaultWrapperClassName
  let defaultClassName

  if (expireTime) {
    const defaultExpTime = Math.floor((expireTime - Date.now()) / 1000)
    if (defaultExpTime < 0) {
      defaultCountDown = 0
    } else {
      defaultCountDown = defaultExpTime
    }
  } else {
    defaultCountDown = expireInSeconds
  }

  const wrapperClassNameOption = {}
  wrapperClassNameOption[style['timer__wrapper']] = true
  if (defaultIsStarted) {
    wrapperClassNameOption[style['timer__wrapper--hidden']] = true
  }
  if (props.wrapperClassName) {
    wrapperClassNameOption[props.className] = true
  }
  defaultWrapperClassName = classNames(wrapperClassNameOption)

  const classNameOption = {}
  classNameOption[style['timer__countDown']] = true
  if (props.className) {
    classNameOption[props.className] = true
  }
  defaultClassName = classNames(classNameOption)

  const [isStarted, setIsStarted] = useState(defaultIsStarted)
  const [countDown, setCountDown] = useState(defaultCountDown)
  const [wrapperClassName, setWrapperClassName] = useState(defaultWrapperClassName)
  const [className, setClassName] = useState(defaultClassName)
  const componentIsMounted = useRef(true)

  const onStart = () => {
    setCountDown(props.expireInSeconds)
    setIsStarted(true)
    if (typeof onReady === 'function') {
      onReady(Date.now() + expireInSeconds * 1000)
    }
  }

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (props.className && !className.includes(props.className)) {
      const classNameOption = {}
      classNameOption[props.className] = true
      classNameOption[style['timer__countDown']] = true
      setClassName(classNames(classNameOption))
    }
  }, [props.className, setClassName])

  useEffect(() => {
    if (props.wrapperClassName && !wrapperClassName.includes(props.wrapperClassName)) {
      const wrapperClassNameOption = {}
      wrapperClassNameOption[props.className] = true
      wrapperClassNameOption[style['timer__wrapper']] = true
      setWrapperClassName(classNames(wrapperClassNameOption))
    }
  }, [props.wrapperClassName, setWrapperClassName])

  useEffect(() => {
    if (componentIsMounted.current) {
      if (isStarted) {
        if (countDown > 0) {
          setTimeout(() => {
            if (componentIsMounted.current) {
              setCountDown(countDown - 1)
            }
          }, 1000)
        } else if (onComplete) {
          onComplete()
        }
      }
    }
  }, [isStarted, countDown, setCountDown])

  useEffect(() => {
    if (isStarted) {
      const wrapperClassNameOption = {}
      if (props.wrapperClassName) {
        wrapperClassNameOption[props.wrapperClassName] = true
      }
      wrapperClassNameOption[style['timer__wrapper']] = true
      wrapperClassNameOption[style['timer__wrapper--hidden']] = true
      const newWrapperClassName = classNames(wrapperClassNameOption)
      if (newWrapperClassName !== wrapperClassName) {
        setWrapperClassName(classNames(wrapperClassNameOption))
      }
    }
  }, [isStarted, setWrapperClassName])

  return (
    <>
      <div className={wrapperClassName}>
        <span className={style['wrapper__label']}>{label}</span>
        <span className={style['wrapper__message']}>
          You have {expireInSeconds} seconds for this question.
        </span>
        <div className={style['wrapper__start']} onClick={onStart}>
          <i className='fas fa-play' />
          <span>Start</span>
        </div>
      </div>
      <div className={className}>time.left: {countDown}s</div>
    </>
  )
}

Timer.propTypes = {
  className: PropTypes.string,
  expireInSeconds: PropTypes.number,
  expireTime: PropTypes.number,
  label: PropTypes.string,
  onComplete: PropTypes.func,
  onReady: PropTypes.func,
  wrapperClassName: PropTypes.string
}

export default Timer
