import PropTypes from 'prop-types'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ROUTES } from '@/routes/root'
import Loading from '@/shared/components/loading'
import { STORE } from '@/constants/strings'

const AuthRoute = (props) => {
  const { component: Component, ...otherProps } = props
  const isAuthorized = useSelector((state) => state.getIn([STORE.AUTH, STORE.IS_AUTHORIZED]))
  const isVerifying = useSelector((state) => state.getIn([STORE.AUTH, STORE.IS_VERIFYING]))

  return (
    <Route
      {...otherProps}
      render={(props) =>
        !isVerifying ? (
          isAuthorized ? (
            <Component {...props} />
          ) : (
            <Redirect to={ROUTES.LOGIN} />
          )
        ) : (
          <Loading />
        )
      }
    />
  )
}

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
}

export default AuthRoute
