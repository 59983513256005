import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'

import {
  LOGIN,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  INTROSPECT,
  INTROSPECT_FULFILLED,
  INTROSPECT_REJECTED
} from '@/store/types'
import { login, logout, introspect } from '@/services/api'

const authenticateEpics = {
  login: ({ username, password, remember }) => ({
    type: LOGIN,
    payload: { username, password, remember }
  }),

  loginFulfilled: () => ({
    type: LOGIN_FULFILLED
  }),

  loginError: (error) => ({
    type: LOGIN_REJECTED,
    payload: error
  }),

  loginEpic: (action$) =>
    action$.pipe(
      ofType(LOGIN),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(login(action.payload)).pipe(
          map(() => authenticateEpics.loginFulfilled()),
          catchError((error) => of(authenticateEpics.loginError(error)))
        )
      )
    ),

  logout: () => ({
    type: LOGOUT
  }),

  logoutFulfilled: () => ({
    type: LOGOUT_FULFILLED
  }),

  logoutError: (error) => ({
    type: LOGOUT_REJECTED,
    payload: error
  }),

  logoutEpic: (action$) =>
    action$.pipe(
      ofType(LOGOUT),
      mergeMap(() =>
        from(logout()).pipe(
          map(() => authenticateEpics.logoutFulfilled()),
          catchError((error) => of(authenticateEpics.logoutError(error)))
        )
      )
    ),

  introspect: () => ({
    type: INTROSPECT
  }),

  introspectFulfilled: (payload) => ({
    type: INTROSPECT_FULFILLED,
    payload
  }),

  introspectError: (error) => ({
    type: INTROSPECT_REJECTED,
    payload: error
  }),

  introspectEpic: (action$) =>
    action$.pipe(
      ofType(INTROSPECT, LOGIN_FULFILLED),
      mergeMap(() =>
        from(introspect()).pipe(
          map(authenticateEpics.introspectFulfilled),
          catchError((error) => of(authenticateEpics.introspectError(error)))
        )
      )
    )
}

export default authenticateEpics
