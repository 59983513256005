/**
 * Replace an item in an array. Find the item with id, and replace it with new
 * item and return the new array.
 * @param {object} item - Item to replace existing item with same ID
 * @param {array} array - The old array
 * @return {array} - new array
 */
export function replaceArrayItem(item, array) {
  const index = array.findIndex((obj) => obj._id === item._id)
  if (index === -1) {
    return array
  } else {
    return [].concat(array.slice(0, index), item, array.slice(index + 1))
  }
}

/**
 * remove a item from array
 * @param {object} item - Item containing _id
 * @param {array} array - The source array. Array of object within each object there should be a key '_id'
 * @return {array} - The new array
 */
export function removeArrayItem(item, array) {
  return array.filter((itemInArray) => item._id !== itemInArray._id)
}

export default {
  replaceArrayItem,
  removeArrayItem
}
