export const GET_DEFENCE_ELEMENT = 'GET_DEFENCE_ELEMENT'
export const GET_DEFENCE_ELEMENT_FULFILLED = 'GET_DEFENCE_ELEMENT_FULFILLED'
export const GET_DEFENCE_ELEMENT_REJECTED = 'GET_DEFENCE_ELEMENT_REJECTED'

export const GET_DEFENCE_ELEMENTS = 'GET_DEFENCE_ELEMENTS'
export const GET_DEFENCE_ELEMENTS_FULFILLED = 'GET_DEFENCE_ELEMENTS_FULFILLED'
export const GET_DEFENCE_ELEMENTS_REJECTED = 'GET_DEFENCE_ELEMENTS_REJECTED'
export const CREATE_DEFENCE_ELEMENT = 'CREATE_DEFENCE_ELEMENT'
export const CREATE_DEFENCE_ELEMENT_FULFILLED = 'CREATE_DEFENCE_ELEMENT_FULFILLED'
export const CREATE_DEFENCE_ELEMENT_REJECTED = 'CREATE_DEFENCE_ELEMENT_REJECTED'

export const UPDATE_DEFENCE_ELEMENT = 'UPDATE_DEFENCE_ELEMENT'
export const UPDATE_DEFENCE_ELEMENT_FULFILLED = 'UPDATE_DEFENCE_ELEMENT_FULFILLED'
export const UPDATE_DEFENCE_ELEMENT_REJECTED = 'UPDATE_DEFENCE_ELEMENT_REJECTED'

export const DELETE_DEFENCE_ELEMENT = 'DELETE_DEFENCE_ELEMENT'
export const DELETE_DEFENCE_ELEMENT_FULFILLED = 'DELETE_DEFENCE_ELEMENT_FULFILLED'
export const DELETE_DEFENCE_ELEMENT_REJECTED = 'DELETE_DEFENCE_ELEMENT_REJECTED'
