import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: lightgrey;
`

const EditorsWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  height: 100%;
`
const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  padding: 10px;
`

const EditorHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
`

const Title = styled.div`
  font-size: 30px;
  height: 10%;
  padding: 10px;
`

export { Wrapper, EditorsWrapper, EditorWrapper, FormWrapper, EditorHeader, Title }
