import qs from 'qs'

import { getUser } from './users'
import API, { createAxiosInstance } from './client'
import { GRANT_TYPES } from './constants'
import ENDPOINTS from './endpoints'
import store, { LOCAL_STORAGE_KEYS } from '@/services/local-storage'
import { errorHandler, extractResponse } from './utils'

/**
 * For AUTH endpoints only
 * @param {object} param
 * @param {string} param.accessToken
 * @param {string} param.tokenType
 */
const getAxiosInstanceConfig = ({ tokenType, accessToken }) => ({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `${tokenType} ${accessToken}`
  }
})

const introspect = async () => {
  let { accessToken, tokenType } = await store.getItem(LOCAL_STORAGE_KEYS.CREDENTIAL)
  if (accessToken && tokenType) {
    const axiosInstance = createAxiosInstance(getAxiosInstanceConfig({ accessToken, tokenType }))
    let body = qs.stringify({
      token: accessToken
    })

    let response
    try {
      response = extractResponse(await axiosInstance.post(ENDPOINTS.AUTH_INTROSPECT, body))
    } catch (err) {
      if (err?.response?.status === 401) {
        try {
          await refresh()
          const credential = await store.getItem(LOCAL_STORAGE_KEYS.CREDENTIAL)
          accessToken = credential.accessToken
          tokenType = credential.tokenType
          body = qs.stringify({
            token: accessToken
          })
          response = extractResponse(await API.post(ENDPOINTS.AUTH_INTROSPECT, body))
        } catch (err) {
          store.clear()
          errorHandler(err)
        }
      } else {
        store.clear()
        errorHandler(err)
      }
    }
    if (!response.active) {
      return Promise.reject()
    }
    API.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
    const userId = response.user_id
    return await getUser(userId)
  } else {
    return Promise.reject()
  }
}

/**
 * Login
 * @param {object} param
 * @param {string} param.username
 * @param {string} param.password
 * @param {bool}   param.remember - if remember is false then ui will not refresh access token
 */
const login = async ({ username, password, remember }) => {
  const body = qs.stringify({
    grant_type: GRANT_TYPES.PASSWORD,
    password,
    username
  })
  try {
    const response = extractResponse(await API.post(ENDPOINTS.AUTH_TOKEN, body))
    const accessToken = response.access_token
    const refreshToken = response.refresh_token
    const tokenType = response.token_type
    // set 'Authorization' header
    API.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
    // update local storage
    await store.setItem(LOCAL_STORAGE_KEYS.CREDENTIAL, {
      accessToken,
      refreshToken,
      tokenType,
      remember
    })
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Logout
 */
const logout = async () => {
  const { accessToken, tokenType } = await store.getItem(LOCAL_STORAGE_KEYS.CREDENTIAL)
  const axiosInstance = createAxiosInstance(getAxiosInstanceConfig({ accessToken, tokenType }))
  const body = qs.stringify({
    token: accessToken
  })
  try {
    await axiosInstance.post(ENDPOINTS.AUTH_REVOKE, body)
  } catch (err) {
    errorHandler(err)
  }
  store.clear()
}

/**
 * Refresh access token
 */
const refresh = async () => {
  const credential = await store.getItem(LOCAL_STORAGE_KEYS.CREDENTIAL)
  if (!credential.remember) {
    return Promise.reject()
  }
  const axiosInstance = createAxiosInstance(
    getAxiosInstanceConfig({ accessToken: credential.accessToken, tokenType: credential.tokenType })
  )
  const body = qs.stringify({
    grant_type: GRANT_TYPES.REFRESH_TOKEN,
    refresh_token: credential.refreshToken
  })
  try {
    const response = extractResponse(await axiosInstance.post(ENDPOINTS.AUTH_TOKEN, body))
    const accessToken = response.access_token
    const refreshToken = response.refresh_token
    const tokenType = response.token_type
    // set 'Authorization' header
    API.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
    // update local storage
    await store.setItem(LOCAL_STORAGE_KEYS.CREDENTIAL, {
      ...credential,
      accessToken,
      tokenType,
      refreshToken
    })
  } catch (err) {
    store.clear()
    errorHandler(err)
  }
}

export { introspect, login, logout, refresh }
