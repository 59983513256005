import { UPDATE_PROGRESS, UPDATE_VALIDATIONS } from './types'
import defaultWidgets from '../widgets'
import { INPUT_TYPE_WIDGET_MAPPING } from '../constants'

const initialState = {
  progress: {},
  validation: {},
  widgets: {},
  typeWidgetMapping: INPUT_TYPE_WIDGET_MAPPING
}

const init = (props = {}) => {
  // const { widgets, typeWidgetMapping } = props
  const widgets = {
    ...defaultWidgets,
    ...props.widgets
  }
  const typeWidgetMapping = {
    ...initialState.typeWidgetMapping,
    ...props.typeWidgetMapping
  }
  const widgetsState = {}
  Object.keys(typeWidgetMapping).forEach((inputType) => {
    widgetsState[inputType] = widgets[typeWidgetMapping[inputType]]
  })
  return {
    ...initialState,
    widgets: widgetsState,
    typeWidgetMapping
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.payload
      }
    case UPDATE_VALIDATIONS:
      return {
        ...state,
        validations: action.payload
      }
    default:
      throw new Error(`Unexpected reducer type ${action.type}`)
  }
}

export { init, reducer }
