// export const GET_ANSWER = 'GET_ANSWER'
// export const GET_ANSWER_FULFILLED = 'GET_ANSWER_FULFILLED'
// export const GET_ANSWER_REJECTED = 'GET_ANSWER_REJECTED'

export const GET_ANSWERS = 'GET_ANSWERS'
export const GET_ANSWERS_FULFILLED = 'GET_ANSWERS_FULFILLED'
export const GET_ANSWERS_REJECTED = 'GET_ANSWERS_REJECTED'

// export const CREATE_ANSWER = 'CREATE_ANSWER'
// export const CREATE_ANSWER_FULFILLED = 'CREATE_ANSWER_FULFILLED'
// export const CREATE_ANSWER_REJECTED = 'CREATE_ANSWER_REJECTED'

export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const UPDATE_ANSWER_FULFILLED = 'UPDATE_ANSWER_FULFILLED'
export const UPDATE_ANSWER_REJECTED = 'UPDATE_ANSWER_REJECTED'

// export const DELETE_ANSWER = 'DELETE_ANSWER'
// export const DELETE_ANSWER_FULFILLED = 'DELETE_ANSWER_FULFILLED'
// export const DELETE_ANSWER_REJECTED = 'DELETE_ANSWER_REJECTED'

export const GET_CURRENT_ANSWER = 'GET_CURRENT_ANSWER'
export const GET_CURRENT_ANSWER_FULFILLED = 'GET_CURRENT_ANSWER_FULFILLED'
export const GET_CURRENT_ANSWER_REJECTED = 'GET_CURRENT_ANSWER_REJECTED'

export const GET_ALL_ANSWERS_UNDER_TASKS = 'GET_ALL_ANSWERS_UNDER_TASKS'
export const GET_ALL_ANSWERS_UNDER_TASKS_FULFILLED = 'GET_ALL_ANSWERS_UNDER_TASKS_FULFILLED'
export const GET_ALL_ANSWERS_UNDER_TASKS_REJECTED = 'GET_ALL_ANSWERS_UNDER_TASKS_REJECTED'
