import { BehaviorSubject, of } from 'rxjs'
import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import { switchMap } from 'rxjs/operators'

import auth from '@/store/reducers/auth'
import questionnaires from '@/store/reducers/questionnaires'
import projects from '@/store/reducers/projects'
import tasks from '@/store/reducers/tasks'
import taskComments from '@/store/reducers/task-comments'
import answers from '@/store/reducers/answers'
import defenceElements from '@/store/reducers/defence-elements'
import { STORE } from '@/constants/strings'

export const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

let reducerRegistry = {}

const reducer$ = new BehaviorSubject()

export const reducerRegistry$ = reducer$.pipe(
  switchMap((reducer) => {
    reducerRegistry = { ...reducerRegistry, ...reducer }
    return of(combineReducers(reducerRegistry))
  })
)

const createRootReducer = (history) => {
  const initialReducer = {
    router: connectRouter(history),
    [STORE.AUTH]: createReducer(auth.initialState, auth.handlers),
    [STORE.QUESTIONNAIRES]: createReducer(questionnaires.initialState, questionnaires.handlers),
    [STORE.PROJECTS]: createReducer(projects.initialState, projects.handlers),
    [STORE.TASKS]: createReducer(tasks.initialState, tasks.handlers),
    [STORE.TASK_COMMENTS]: createReducer(taskComments.initialState, taskComments.handlers),
    [STORE.ANSWERS]: createReducer(answers.initialState, answers.handlers),
    [STORE.DEFENCE_ELEMENTS]: createReducer(defenceElements.initialState, defenceElements.handlers)
  }
  reducerRegistry$.next(initialReducer)
  return combineReducers(initialReducer)
}

export default createRootReducer
