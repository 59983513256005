import React from 'react'
import PropTypes from 'prop-types'
import { chunk } from 'lodash'
import styles from '../../styles/index.module.less'

const CheckBoxInput = (props) => {
  const { CREATE_VALUE, disabled, options, variable } = props
  const value = props.value.value
  const textValue = props.textValue.value
  const onOptionChange = (optionValue) => {
    const newVal = [...value]
    const index = newVal.findIndex((el) => el === optionValue)
    if (index !== -1) {
      newVal.splice(index, 1)
    } else {
      newVal.push(optionValue)
    }
    props.onOptionChange({ ...props.value, value: newVal })
  }

  const onTextChange = (e) => {
    const newVal = {
      target: {
        name: `${variable}-other`,
        value: {
          value: e.target.value
        }
      }
    }
    props.onTextChange(newVal)
  }

  if (options && options.length < 5) {
    return (
      <div className={styles['radio__container']}>
        {options.map((option, idx) => {
          const { optionLabel, optionValue } = option
          return (
            <div className={styles['option__container']} key={idx}>
              <input
                checked={value ? value.indexOf(optionValue) > -1 : false}
                className={styles['option__select']}
                disabled={disabled}
                name={variable}
                onChange={() => onOptionChange(optionValue)}
                type='checkbox'
              />
              <label className={styles['option__label']}>{optionLabel}</label>
            </div>
          )
        })}
        {value.indexOf(CREATE_VALUE) > -1 && (
          <input
            className={styles['text-input']}
            name={`${variable}-other`}
            value={textValue}
            placeholder='Please specify other'
            onChange={onTextChange}
            type='text'
            disabled={disabled}
          />
        )}
      </div>
    )
  }
  return (
    <div className={styles['radio__container']}>
      {chunk(options, 2).map((setOfOption, idx1) => (
        <div className={styles['radio__row']} key={idx1}>
          {setOfOption.map((option, idx2) => {
            const { optionLabel, optionValue } = option
            return (
              <div className={styles['option__container--col-12']} key={idx2}>
                <input
                  checked={value && value.indexOf(optionValue) > -1}
                  className={styles['option__select']}
                  disabled={disabled}
                  name={variable}
                  onChange={() => onOptionChange(optionValue)}
                  type='checkbox'
                />
                <label className={styles['option__label']}>{optionLabel}</label>
              </div>
            )
          })}
        </div>
      ))}
      {value.indexOf(CREATE_VALUE) > -1 && (
        <input
          className={styles['text-input']}
          disabled={disabled}
          name={`${variable}-other`}
          onChange={onTextChange}
          placeholder='Please specify other'
          type='text'
          value={textValue}
        />
      )}
    </div>
  )
}

CheckBoxInput.propTypes = {
  CREATE_VALUE: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onOptionChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  textValue: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  variable: PropTypes.string.isRequired
}

export default CheckBoxInput
