import localforage from 'localforage'

const LOCAL_STORAGE_KEYS = {
  CREDENTIAL: 'credential'
}

const store = localforage.createInstance({
  name: window.location.origin,
  version: 1.0
})

export default store
export { LOCAL_STORAGE_KEYS }
