const ttl = (strings, ...keys) => {
  return function (collection) {
    let result = [strings[0]]
    collection = collection || {}
    keys.forEach(function (key, i) {
      result.push(collection[key], strings[i + 1])
    })
    return result.join('')
  }
}

const ENDPOINTS = {
  ANSWER: ttl`/v1/answers/${'answerId'}`,
  ANSWERS: ttl`/v1/questionnaires/${'questionnaireId'}/answers`,
  AUTH_INTROSPECT: '/auth/introspect',
  AUTH_REVOKE: '/auth/revoke',
  AUTH_TOKEN: '/auth/token',
  PROJECT: ttl`/v1/projects/${'projectId'}`,
  PROJECTS: '/v1/projects',
  TASK: ttl`/v1/tasks/${'taskId'}`,
  TASKS: ttl`/v1/projects/${'projectId'}/tasks`,
  QUESTIONNAIRE: ttl`/v1/questionnaires/${'questionnaireId'}`,
  QUESTIONNAIRES: ttl`/v1/projects/${'projectId'}/questionnaires`,
  USER: ttl`/v1/users/${'id'}`,
  USERS: '/v1/users',
  TASK_COMMENT: ttl`/v1/tasks/${'taskId'}/comments/${'commentId'}`,
  TASK_COMMENTS: ttl`/v1/tasks/${'taskId'}/comments`,
  DEFENCE_ELEMENTS: '/v1/defence-elements',
  DEFENCE_ELEMENT: ttl`/v1/defence-elements/${'defenceElementId'}`
}

export default ENDPOINTS
