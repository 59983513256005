const STRINGS = {
  USER_LOGIN_TITLE: 'Sign in',
  USER_LOGIN_PLACEHOLDER_USERNAME: 'Username',
  USER_LOGIN_PLACEHOLDER_PASSWORD: 'Password',
  USER_LOGIN_DISPLAY_REMEMBER_ME: 'Remember me',
  USER_LOGIN_DISPLAY_SUBMIT: 'Sign In',
  USER_LOGIN_LOADING_TIP: 'Sign in...',
  USER_LOGIN_VALIDATION_EMPTY_USERNAME: 'Please input your username.',
  USER_LOGIN_VALIDATION_EMPTY_PASSWORD: 'Please input your password.',
  USER_LOGIN_ERROR_INCORRECT_KEYPASS: 'Incorrect username or password.',
  USER_LOGIN_ERROR_UNKNOWN: 'Unknown error.',

  TASKS_ERROR: 'No tasks under the project.',

  COMMENT_CREATION_PLACEHOLDER: 'Put comments here',
  COMMENT_CREATION_ERROR_VALIDATION: 'Wrong format'
}

const STORE = {
  ANSWERS: 'answers',
  CURRENT_ANSWER: 'currentAnswer',
  ANSWERS_UNDER_TASKS: 'answersUnderTasks',
  AUTH: 'auth',
  CURRENT_PROJECT: 'currentProject',
  CURRENT_TASK: 'currentTask',
  PROJECTS: 'projects',
  QUESTIONNAIRES: 'questionnaires',
  CURRENT_QUESTIONNAIRE: 'currentQuestionnaire',
  TASKS: 'tasks',
  TASK_COMMENTS: 'taskComments',
  DEFENCE_ELEMENTS: 'defenceElements',

  ERROR: 'error',
  IS_PENDING: 'isPending',
  DATA: 'data',

  IS_AUTHORIZED: 'isAuthorized',
  IS_LOGGING_OUT: 'isLoggingOut',
  IS_VERIFYING: 'isVerifying',
  USER: 'user',
  CACHED_USERS: 'cachedUsers',
  ID: '_id',
  USER_ID: 'userId'
}

const QUESTION_SET = {
  COLUMN: 'column',
  ID: '_id',
  INPUT_TYPE: 'inputType',
  OPTIONS: 'options',
  VARIABLE: 'variable'
}

const QUESTION_VALIDATION = {
  MAX_VALUE: 'maxValue',
  MIN_VALUE: 'minValue',
  NUMBER: 'number'
}

const OPTIONS = {
  CREATED_AT: 'createdAt',
  ID: '_id',
  UPDATED_AT: 'updatedAT',
  VALUES: 'values',
  VALUE_TYPE: 'valueType',
  VERSION: '__v'
}

const DEFENCE_ELEMENT = {
  DOMAIN: 'domain',
  NAME: 'name'
}

export default STRINGS
export { DEFENCE_ELEMENT, OPTIONS, QUESTION_SET, QUESTION_VALIDATION, STORE }
