import { QS_CONSTANTS } from '../constants'
import { isObject } from 'lodash'

/**
 * Return orderal suffix e.g. 1 = 1st, 2 = 2nd
 * @param i
 * @returns {string}
 */
const getOrdinalPrefix = (i) => {
  var j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

/**
 * Return the sub section initial, e.g. first section is '1. ', second is '2. '.
 * @param index
 * @returns {*}
 */
const getSubSectionLabel = (index) => {
  return index + 1
}

/**
 * Return the section initial, e.g. first section is 'A. ', 26th section is 'Z' 27th section is 'AA. '.
 * @param index
 * @returns {*}
 */
const getSectionLable = (index) => {
  let a = index + 1
  let initials = []
  while (a > 26) {
    initials.push(a % 26)
    a = Math.floor(a / 26)
  }
  initials.push(a)
  initials = initials.reverse()
  const alphabet = [...Array(26).keys()].map((i) => String.fromCharCode(i + 97).toUpperCase())
  let label = ''
  initials.forEach((initial) => {
    label += alphabet[initial - 1]
  })
  return label
}

const getQuestionSetLabel = (idx, path, questionSet) => {
  const sectionLabel =
    path.length > 1
      ? questionSet.display === QS_CONSTANTS.REPEAT_SET
        ? getOrdinalPrefix(idx + 1)
        : getSubSectionLabel(idx)
      : getSectionLable(idx)
  const concatSymbol = questionSet.display !== QS_CONSTANTS.REPEAT_SET ? '. ' : ' '
  return `${sectionLabel}${concatSymbol}${questionSet.label}`
}

/**
 * Determine ff a question is required to be answered before form being submitted.
 * @param q {Object} question object
 * @returns {boolean} if an answer to the question is required.
 */
const isQuestionRequired = (q) => {
  return q.validation && q.validation.isRequired === true
}

/**
 * filter the vocabulary to find the corresponding options for a question
 * @param {String} optionsID: The ID of a set of vocabularies
 * @param {Array} vocabularies: All set of vocabularies
 * @return {Array} An array of options.
 */
const findOptionsInVocabulary = (optionsID, vocabularies) => {
  if (vocabularies) {
    const options = vocabularies.filter((vocabulary) => vocabulary._id === optionsID)
    if (options.length > 0) {
      return options[0].values
    }
    return []
  }
  return []
}

/**
 * Get radio option's label and value
 * @param {Object|string} option
 * @param {string} option.label
 * @param {string|number} option.value
 * @return {Object} The label and value of input option
 */
const getOptionLabelAndValue = (option) => {
  let optionLabel
  let optionValue
  if (isObject(option)) {
    optionLabel = option.label
    optionValue = option.value
  } else {
    optionValue = optionLabel = option
  }
  return {
    optionLabel,
    optionValue
  }
}

export { getQuestionSetLabel, isQuestionRequired, findOptionsInVocabulary, getOptionLabelAndValue }
