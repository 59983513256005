import Immutable from 'immutable'

import {
  GET_TASK,
  GET_TASK_FULFILLED,
  GET_TASK_REJECTED,
  GET_TASKS,
  GET_TASKS_FULFILLED,
  GET_TASKS_REJECTED
} from '@/store/types'
import { STORE } from '@/constants/strings'

const DEFAULT_STATE = {
  [STORE.DATA]: null,
  [STORE.ERROR]: null,
  [STORE.IS_PENDING]: false
}
const initialState = new Immutable.Map({
  ...DEFAULT_STATE,
  [STORE.CURRENT_TASK]: { ...DEFAULT_STATE }
})

export default {
  initialState,
  handlers: {
    [GET_TASK]: (state) =>
      state.merge({
        [STORE.CURRENT_TASK]: {
          ...DEFAULT_STATE,
          [STORE.IS_PENDING]: true
        }
      }),
    [GET_TASK_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_TASK]: {
          ...DEFAULT_STATE,
          [STORE.DATA]: action.payload,
          [STORE.IS_PENDING]: false
        }
      }),
    [GET_TASK_REJECTED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_TASK]: {
          ...DEFAULT_STATE,
          [STORE.ERROR]: action.payload,
          [STORE.IS_PENDING]: false
        }
      }),
    [GET_TASKS]: (state) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.IS_PENDING]: true
      }),
    [GET_TASKS_FULFILLED]: (state, action) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.DATA]: action.payload,
        [STORE.IS_PENDING]: false
      }),
    [GET_TASKS_REJECTED]: (state, action) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.ERROR]: action.payload,
        [STORE.IS_PENDING]: false
      })
    // [CREATE_TASK]: (state) =>
    //   state.merge({
    //     tasks: { ...state.get('tasks'), isPending: true }
    //   }),
    // [CREATE_TASK_FULFILLED]: (state, action) =>
    //   state.merge({
    //     tasks: {
    //       data: [action.payload, ...state.get('tasks').data],
    //       isPending: false,
    //       error: null
    //     }
    //   }),
    // [CREATE_TASK_REJECTED]: (state, action) =>
    //   state.merge({
    //     tasks: {
    //       ...state.get('tasks'),
    //       isPending: false,
    //       error: action.payload
    //     }
    //   }),
    // [UPDATE_TASK]: (state) =>
    //   state.merge({
    //     tasks: { ...state.get('tasks'), isPending: true }
    //   }),
    // [UPDATE_TASK_FULFILLED]: (state, action) =>
    //   state.merge({
    //     tasks: {
    //       data: [replaceArrayItem(action.payload, state.get('tasks').data)],
    //       isPending: false,
    //       error: null
    //     }
    //   }),
    // [UPDATE_TASK_REJECTED]: (state, action) =>
    //   state.merge({
    //     tasks: {
    //       ...state.get('tasks'),
    //       isPending: false,
    //       error: action.payload
    //     }
    //   }),
    // [DELETE_TASK]: (state) =>
    //   state.merge({
    //     tasks: { ...state.get('tasks'), isPending: true }
    //   }),
    // [DELETE_TASK_FULFILLED]: (state, action) =>
    //   state.merge({
    //     tasks: {
    //       data: removeArrayItem(action.payload, state.get('tasks').data),
    //       isPending: false,
    //       error: null
    //     }
    //   }),
    // [DELETE_TASK_REJECTED]: (state, action) =>
    //   state.merge({
    //     tasks: {
    //       ...state.get('tasks'),
    //       isPending: false,
    //       error: action.payload
    //     }
    //   })
  }
}
