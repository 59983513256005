import React from 'react'
import PropType from 'prop-types'

import styles from '../../styles/index.module.less'
import QuestionSet from '../question-set'

const Form = (props) => {
  const { name, questionSet, vocabularies, answers, index, disabled } = props
  return (
    <form id={name} name={name} className={styles.questions}>
      {questionSet &&
        questionSet.questions &&
        questionSet.questions.map((question, idx) => {
          if (index === idx) {
            const path = [question.name]
            return (
              <QuestionSet
                answers={answers}
                disabled={disabled}
                index={idx}
                key={idx}
                path={path}
                questionSet={question}
                vocabularies={vocabularies}
              />
            )
          }
          return null
        })}
    </form>
  )
}

Form.propTypes = {
  name: PropType.string,
  answers: PropType.object,
  index: PropType.number,
  questionSet: PropType.object,
  vocabularies: PropType.array,
  disabled: PropType.bool
}

export default Form
