import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler } from './utils'

/**
 * Get a task
 * @param {string} taskId: uuid that referring a single task
 */
const getTask = async ({ taskId }) => {
  try {
    const response = await API.get(ENDPOINTS.TASK({ taskId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get all tasks under the project
 * @param {string} projectId: uuid that referring a single project
 */
const getTasks = async ({ projectId }) => {
  try {
    const response = await API.get(ENDPOINTS.TASKS({ projectId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * create a task under the project
 * @param {string} projectId: uuid that referring a single project
 * @param {object} task: the structure of the task
 */
const createTask = async ({ projectId, task }) => {
  try {
    const response = await API.post(ENDPOINTS.TASKS({ projectId }), task)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * update a task
 * @param {string} taskId: uuid that referring a single task
 * @param {object} task: the updated structure of the task
 */
const updateTask = async ({ taskId, task }) => {
  try {
    const response = await API.put(ENDPOINTS.TASK({ taskId }), task)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * delete a task
 * @param {string} taskId: uuid that referring a single task
 */
const deleteTask = async ({ taskId }) => {
  try {
    const response = await API.delete(ENDPOINTS.TASK({ taskId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

export { getTask, getTasks, createTask, updateTask, deleteTask }
