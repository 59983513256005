import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler } from './utils'

/**
 * Get a questionnaire
 * @param {string} questionnaireId: uuid that referring a single questionnaire
 */
const getQuestionnaire = async ({ questionnaireId }) => {
  try {
    const response = await API.get(ENDPOINTS.QUESTIONNAIRE({ questionnaireId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get all questionnaires under the project
 * @param {string} projectId: uuid that referring a single project
 * @param {string|undefined} taskId: optional uuid that referring a single task (if specified, the
 * results will be all questionnaires under the task)
 */
const getQuestionnaires = async ({ projectId, taskId }) => {
  try {
    const response = await API.get(
      ENDPOINTS.QUESTIONNAIRES({ projectId }),
      taskId !== undefined ? new Object({ params: { taskId: taskId } }) : {}
    )
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * create a questionnaire
 * @param {string} projectId: uuid that referring a single project
 * @param {object} questionnaire: the structure of the questionnaire
 * @param {string|undefined} taskId: optional uuid that referring a single task under the project (
 * if specified, the questionnarie will be created under a task)
 */
const createQuestionnaire = async ({ projectId, questionnaire, taskId }) => {
  try {
    const response = await API.post(
      ENDPOINTS.QUESTIONNAIRES({ projectId }),
      questionnaire,
      taskId !== undefined ? new Object({ params: { taskId: taskId } }) : {}
    )
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * update a questionnaire
 * @param {string} questionnaireId: uuid that referring a single questionnaire under the project
 * @param {object} questionnaire: the updated structure of the questionnaire
 */
const updateQuestionnaire = async ({ questionnaireId, questionnaire }) => {
  try {
    const response = await API.put(ENDPOINTS.QUESTIONNAIRE({ questionnaireId }), questionnaire)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * delete a questionnaire
 * @param {string} questionnaireId: uuid that referring a single questionnaire under the project
 */
const deleteQuestionnaire = async ({ questionnaireId }) => {
  try {
    const response = await API.delete(ENDPOINTS.QUESTIONNAIRE({ questionnaireId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

export {
  getQuestionnaire,
  getQuestionnaires,
  createQuestionnaire,
  updateQuestionnaire,
  deleteQuestionnaire
}
