import Immutable from 'immutable'

import {
  CREATE_QUESTIONNAIRE,
  CREATE_QUESTIONNAIRE_FULFILLED,
  CREATE_QUESTIONNAIRE_REJECTED,
  DELETE_QUESTIONNAIRE,
  DELETE_QUESTIONNAIRE_FULFILLED,
  DELETE_QUESTIONNAIRE_REJECTED,
  GET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_FULFILLED,
  GET_QUESTIONNAIRE_REJECTED,
  GET_QUESTIONNAIRE_UNDER_TASK,
  GET_QUESTIONNAIRE_UNDER_TASK_FULFILLED,
  GET_QUESTIONNAIRE_UNDER_TASK_REJECTED,
  GET_QUESTIONNAIRES,
  GET_QUESTIONNAIRES_FULFILLED,
  GET_QUESTIONNAIRES_REJECTED,
  UPDATE_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE_FULFILLED,
  UPDATE_QUESTIONNAIRE_REJECTED
} from '@/store/types'
import { removeArrayItem, replaceArrayItem } from '@/services/utils/array'
import { STORE } from '@/constants/strings'

const DEFAULT_STATE = {
  [STORE.DATA]: null,
  [STORE.ERROR]: null,
  [STORE.IS_PENDING]: false
}

const initialState = new Immutable.Map({
  ...DEFAULT_STATE,
  [STORE.QUESTIONNAIRES]: { ...DEFAULT_STATE, [STORE.DATA]: [] },
  [STORE.CURRENT_QUESTIONNAIRE]: { ...DEFAULT_STATE }
})

export default {
  initialState,
  handlers: {
    [GET_QUESTIONNAIRE]: (state) =>
      state.merge({
        [STORE.IS_PENDING]: true
      }),
    [GET_QUESTIONNAIRE_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.IS_PENDING]: false,
        [STORE.DATA]: action.payload,
        [STORE.ERROR]: null
      }),
    [GET_QUESTIONNAIRE_REJECTED]: (state, action) =>
      state.merge({
        [STORE.IS_PENDING]: false,
        [STORE.DATA]: null,
        [STORE.ERROR]: action.payload
      }),
    [GET_QUESTIONNAIRES]: (state) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: { ...state.get(STORE.QUESTIONNAIRES), [STORE.IS_PENDING]: true }
      }),
    [GET_QUESTIONNAIRES_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: {
          [STORE.IS_PENDING]: false,
          [STORE.DATA]: action.payload,
          [STORE.ERROR]: null
        }
      }),
    [GET_QUESTIONNAIRES_REJECTED]: (state, action) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: {
          [STORE.IS_PENDING]: false,
          [STORE.DATA]: null,
          [STORE.ERROR]: action.payload
        }
      }),
    [CREATE_QUESTIONNAIRE]: (state) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: { ...state.get(STORE.QUESTIONNAIRES), [STORE.IS_PENDING]: true }
      }),
    [CREATE_QUESTIONNAIRE_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: {
          [STORE.DATA]: [action.payload, ...state.get(STORE.QUESTIONNAIRES)[STORE.DATA]],
          [STORE.IS_PENDING]: false,
          [STORE.ERROR]: null
        }
      }),
    [CREATE_QUESTIONNAIRE_REJECTED]: (state, action) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: {
          ...state.get(STORE.QUESTIONNAIRES),
          [STORE.IS_PENDING]: false,
          [STORE.ERROR]: action.payload
        }
      }),
    [UPDATE_QUESTIONNAIRE]: (state) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: { ...state.get(STORE.QUESTIONNAIRES), [STORE.IS_PENDING]: true }
      }),
    [UPDATE_QUESTIONNAIRE_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: {
          [STORE.DATA]: replaceArrayItem(
            action.payload,
            state.get(STORE.QUESTIONNAIRES)[STORE.DATA]
          ),
          [STORE.IS_PENDING]: false,
          [STORE.ERROR]: null
        }
      }),
    [UPDATE_QUESTIONNAIRE_REJECTED]: (state, action) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: {
          ...state.get(STORE.QUESTIONNAIRES),
          [STORE.IS_PENDING]: false,
          [STORE.ERROR]: action.payload
        }
      }),
    [DELETE_QUESTIONNAIRE]: (state) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: { ...state.get(STORE.QUESTIONNAIRES), [STORE.IS_PENDING]: true }
      }),
    [DELETE_QUESTIONNAIRE_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: {
          [STORE.DATA]: removeArrayItem(
            action.payload,
            state.get(STORE.QUESTIONNAIRES)[STORE.DATA]
          ),
          [STORE.IS_PENDING]: false,
          [STORE.ERROR]: null
        }
      }),
    [DELETE_QUESTIONNAIRE_REJECTED]: (state, action) =>
      state.merge({
        [STORE.QUESTIONNAIRES]: {
          ...state.get(STORE.QUESTIONNAIRES),
          [STORE.IS_PENDING]: false,
          [STORE.ERROR]: action.payload
        }
      }),

    [GET_QUESTIONNAIRE_UNDER_TASK]: (state) =>
      state.merge({
        [STORE.CURRENT_QUESTIONNAIRE]: {
          ...DEFAULT_STATE,
          [STORE.IS_PENDING]: true
        }
      }),
    [GET_QUESTIONNAIRE_UNDER_TASK_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_QUESTIONNAIRE]: {
          [STORE.IS_PENDING]: false,
          [STORE.DATA]: action.payload,
          [STORE.ERROR]: null
        }
      }),
    [GET_QUESTIONNAIRE_UNDER_TASK_REJECTED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_QUESTIONNAIRE]: {
          [STORE.IS_PENDING]: false,
          [STORE.DATA]: null,
          [STORE.ERROR]: action.payload
        }
      })
  }
}
