/* eslint no-unused-vars: 0 */
import styles from '@/styles/branding.less' // for branding vars
import styled from 'styled-components'

export const AcejetLogo = styled.div`
  background: url(/public/images/acejet-wide.svg);
  background-size: auto 40px;
  background-repeat: no-repeat;
  display: block;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  right: 0;
  height: 40px;
  position: absolute;
`

export const User = styled.div`
  color: white;
  padding: 0 7px;
  display: flex;
  flex-direction: row;
  line-height: 14px;
  align-items: flex-end;

  > * {
    margin-left: 7px;
  }
`
