export const LOGIN = 'LOGIN'
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED'
export const LOGIN_REJECTED = 'LOGIN_REJECTED'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED'
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED'

export const INTROSPECT = 'INTROSPECT'
export const INTROSPECT_FULFILLED = 'INTROSPECT_FULFILLED'
export const INTROSPECT_REJECTED = 'INTROSPECT_REJECTED'
