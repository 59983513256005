import React from 'react'
import styles from './styles/index.module.less'
import Header from '@/components/header'
import { Layout } from 'antd'
import JsonConverter from '@/components/json-converter'
const FormEngineDemo = () => {
  return (
    <Layout className={styles.layout}>
      <Header />
      <Layout className={styles.content}>
        <JsonConverter />
      </Layout>
    </Layout>
  )
}

export default FormEngineDemo
