import axios from 'axios'
import { unauthorizedInterceptor } from './utils'

const API = axios.create({
  baseURL: process.env.REACT_APP_API_PATH || '/api'
})

const createAxiosInstance = (config) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_PATH || '/api',
    ...config
  })
}

unauthorizedInterceptor(API)

export default API
export { createAxiosInstance }
