export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE'
export const GET_QUESTIONNAIRE_FULFILLED = 'GET_QUESTIONNAIRE_FULFILLED'
export const GET_QUESTIONNAIRE_REJECTED = 'GET_QUESTIONNAIRE_REJECTED'

export const GET_QUESTIONNAIRES = 'GET_QUESTIONNAIRES'
export const GET_QUESTIONNAIRES_FULFILLED = 'GET_QUESTIONNAIRES_FULFILLED'
export const GET_QUESTIONNAIRES_REJECTED = 'GET_QUESTIONNAIRES_REJECTED'

export const CREATE_QUESTIONNAIRE = 'CREATE_QUESTIONNAIRE'
export const CREATE_QUESTIONNAIRE_FULFILLED = 'CREATE_QUESTIONNAIRE_FULFILLED'
export const CREATE_QUESTIONNAIRE_REJECTED = 'CREATE_QUESTIONNAIRE_REJECTED'

export const UPDATE_QUESTIONNAIRE = 'UPDATE_QUESTIONNAIRE'
export const UPDATE_QUESTIONNAIRE_FULFILLED = 'UPDATE_QUESTIONNAIRE_FULFILLED'
export const UPDATE_QUESTIONNAIRE_REJECTED = 'UPDATE_QUESTIONNAIRE_REJECTED'

export const DELETE_QUESTIONNAIRE = 'DELETE_QUESTIONNAIRE'
export const DELETE_QUESTIONNAIRE_FULFILLED = 'DELETE_QUESTIONNAIRE_FULFILLED'
export const DELETE_QUESTIONNAIRE_REJECTED = 'DELETE_QUESTIONNAIRE_REJECTED'

export const GET_QUESTIONNAIRE_UNDER_TASK = 'GET_QUESTIONNAIRE_UNDER_TASK'
export const GET_QUESTIONNAIRE_UNDER_TASK_FULFILLED = 'GET_QUESTIONNAIRE_UNDER_TASK_FULFILLED'
export const GET_QUESTIONNAIRE_UNDER_TASK_REJECTED = 'GET_QUESTIONNAIRE_UNDER_TASK_REJECTED'
