export const GET_TASK = 'GET_TASK'
export const GET_TASK_FULFILLED = 'GET_TASK_FULFILLED'
export const GET_TASK_REJECTED = 'GET_TASK_REJECTED'

export const GET_TASKS = 'GET_TASKS'
export const GET_TASKS_FULFILLED = 'GET_TASKS_FULFILLED'
export const GET_TASKS_REJECTED = 'GET_TASKS_REJECTED'

export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_FULFILLED = 'CREATE_TASK_FULFILLED'
export const CREATE_TASK_REJECTED = 'CREATE_TASK_REJECTED'

export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_FULFILLED = 'UPDATE_TASK_FULFILLED'
export const UPDATE_TASK_REJECTED = 'UPDATE_TASK_REJECTED'

export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_FULFILLED = 'DELETE_TASK_FULFILLED'
export const DELETE_TASK_REJECTED = 'DELETE_TASK_REJECTED'
