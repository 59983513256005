import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Layout, Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import '@/styles/branding.less'
import styles from './styles/index.module.less'
import { AcejetLogo, User } from './styles'
import { ROUTES } from '@/routes/root'
import authEpics from '@/store/epics/auth'
import { epic$ } from '@/store/epics'
import { STORE } from '@/constants/strings'
import { connect } from 'react-redux'
import Avatar from '@/components/avatar'

const Header = (props) => {
  const { user = {} } = props
  const dispatch = useDispatch()
  const onLogout = () => dispatch(authEpics.logout())

  useEffect(() => {
    epic$.next(authEpics.logoutEpic)
  }, [])

  const { title = '' } = props
  return (
    <Layout.Header className={styles.header}>
      <Row className={styles.row}>
        <Col span={6} className={styles.col}>
          {!props.hideLogo && (
            <Link to={ROUTES.HOME}>
              <AcejetLogo />
            </Link>
          )}
        </Col>
        <Col span={12} className={styles.title}>
          {title}
        </Col>
        <Col span={6} className={styles.logout}>
          {/* <Link to={ROUTES.FORM_ENGINE_DEMO} className={styles.link}>
            <Button type='primary' size='small'>
              Form Engine Demo
            </Button>
          </Link> */}
          <User>
            <span>{user.fullName || user.username}</span>
            <Avatar user={user} size={'24px'} />
            <Button type='primary' size='small' onClick={onLogout}>
              Logout
            </Button>
          </User>
        </Col>
      </Row>
    </Layout.Header>
  )
}

Header.propTypes = {
  hideLogo: PropTypes.bool,
  title: PropTypes.string,
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.getIn([STORE.AUTH, STORE.USER])
})

export default connect(mapStateToProps)(Header)
