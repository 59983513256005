import PropType from 'prop-types'
import React from 'react'
import Youtube from 'react-youtube'

import { VideoWrapper } from './styles'
/**
 * Component to render video.
 * @returns {*}
 * @constructor
 * @param props
 */
const Video = (props) => {
  let opts = {
    height: 500,
    width: 700
  }

  return (
    <VideoWrapper>
      <Youtube videoId={props.videoId} opts={opts} />
    </VideoWrapper>
  )
}

Video.propTypes = {
  videoId: PropType.string
}

export default Video
