import React, { useState } from 'react'
import { Wrapper, EditorsWrapper, FormWrapper, EditorWrapper, EditorHeader, Title } from './styles'
import styles from './styles/index.module.less'
import JsonEditor from './json-editor'
import mockQuestionSet from '@/mock/engine/questionSet'
import mockOptions from '@/mock/engine/options'
import { Alert } from 'antd'
import QuestionsForm from '@/components/questions-form'

const JsonConverter = () => {
  const defaultQuestionSet = mockQuestionSet[0]
  const defaultOptions = mockOptions

  const [questionSet, setQuestionSet] = useState(defaultQuestionSet)
  const [options, setOptions] = useState(defaultOptions)

  const [errorQuestionSet, setErrorQuestionSet] = useState(null)
  const [errorOptions, setErrorOptions] = useState(null)

  return (
    <Wrapper>
      <EditorsWrapper>
        <EditorWrapper>
          <EditorHeader>
            <h2>Question Set</h2>
            {errorQuestionSet === null ? null : (
              <Alert
                message={errorQuestionSet.message}
                type='error'
                showIcon
                closable
                className={styles.alert}
              />
            )}
          </EditorHeader>
          <JsonEditor
            defaultObj={questionSet}
            onObjectChange={setQuestionSet}
            onErrorChange={setErrorQuestionSet}
          />
        </EditorWrapper>
        <EditorWrapper>
          <EditorHeader>
            <h2>Options</h2>
            {errorOptions === null ? null : (
              <Alert
                message={errorOptions.message}
                type='error'
                showIcon
                closable
                className={styles.alert}
              />
            )}
          </EditorHeader>
          <JsonEditor
            defaultObj={options}
            onObjectChange={setOptions}
            onErrorChange={setErrorOptions}
          />
        </EditorWrapper>
      </EditorsWrapper>
      <FormWrapper>
        <Title>Generated Form</Title>
        <div className={styles.form}>
          <QuestionsForm defaultQuestionnaire={{ questionSet, options }} />
        </div>
      </FormWrapper>
    </Wrapper>
  )
}

export default JsonConverter
