import React from 'react'
import PropType from 'prop-types'
import { isEqual } from 'lodash'

import { INPUT_TYPE } from '../../constants'

const DatePicker = (props) => {
  const { questionSet, question, value, path, disabled, Component, Data } = props
  const variable = question.variable

  return (
    <Component
      onChange={(date) =>
        Data.handleDatePickerChange(
          date,
          variable,
          questionSet.name,
          INPUT_TYPE.DATE,
          questionSet,
          question,
          path
        )
      }
      disabled={disabled}
      format='DD/MM/YYYY'
      inputProps={{ disabled }}
      placeholder='DD/MM/YYYY'
      value={value}
    />
  )
}

DatePicker.propTypes = {
  value: PropType.object,
  Data: PropType.object,
  path: PropType.array,
  question: PropType.object,
  questionSet: PropType.object,
  Component: PropType.oneOfType([PropType.func, PropType.object]),
  disabled: PropType.bool
}

const areEqual = (prevProps, props) => {
  const areEqual = isEqual(prevProps.value, props.value) && !!prevProps.disable === !!props.disable
  return areEqual
}

export default React.memo(DatePicker, areEqual)
