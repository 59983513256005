// export const GET_TASK_COMMENT = 'GET_TASK_COMMENT'
// export const GET_TASK_COMMENT_FULFILLED = 'GET_TASK_COMMENT_FULFILLED'
// export const GET_TASK_COMMENT_REJECTED = 'GET_TASK_COMMENT_REJECTED'

export const GET_TASK_COMMENTS = 'GET_TASK_COMMENTS'
export const GET_TASK_COMMENTS_FULFILLED = 'GET_TASK_COMMENTS_FULFILLED'
export const GET_TASK_COMMENTS_REJECTED = 'GET_TASK_COMMENTS_REJECTED'

export const CREATE_TASK_COMMENT = 'CREATE_TASK_COMMENT'
export const CREATE_TASK_COMMENT_FULFILLED = 'CREATE_TASK_COMMENT_FULFILLED'
export const CREATE_TASK_COMMENT_REJECTED = 'CREATE_TASK_COMMENT_REJECTED'

// export const UPDATE_TASK_COMMENT = 'UPDATE_TASK_COMMENT'
// export const UPDATE_TASK_COMMENT_FULFILLED = 'UPDATE_TASK_COMMENT_FULFILLED'
// export const UPDATE_TASK_COMMENT_REJECTED = 'UPDATE_TASK_COMMENT_REJECTED'

// export const DELETE_TASK_COMMENT = 'DELETE_TASK_COMMENT'
// export const DELETE_TASK_COMMENT_FULFILLED = 'DELETE_TASK_COMMENT_FULFILLED'
// export const DELETE_TASK_COMMENT_REJECTED = 'DELETE_TASK_COMMENT_REJECTED'

export const LIKE_TASK_COMMENT = 'LIKE_TASK_COMMENT'
export const LIKE_TASK_COMMENT_FULFILLED = 'LIKE_TASK_COMMENT_FULFILLED'
export const LIKE_TASK_COMMENT_REJECTED = 'LIKE_TASK_COMMENT_REJECTED'

export const DISLIKE_TASK_COMMENT = 'DISLIKE_TASK_COMMENT'
export const DISLIKE_TASK_COMMENT_FULFILLED = 'DISLIKE_TASK_COMMENT_FULFILLED'
export const DISLIKE_TASK_COMMENT_REJECTED = 'DISLIKE_TASK_COMMENT_REJECTED'
