import React, { useContext } from 'react'
import PropType from 'prop-types'

import { DataContext, WidgetsContext } from '../../context'
import { QS_CONSTANTS, INPUT_TYPE } from '../../constants'
import TextInput from '../text-input'
import DatePicker from '../date-picker'
import TimePicker from '../time-picker'
import RadioInput from '../radio-input'
import styles from '../../styles/index.module.less'

const QuestionSetToRow = (props) => {
  const { index, path, questionSet, vocabularies, disabled } = props

  const Data = useContext(DataContext)
  const Widgets = useContext(WidgetsContext)
  return (
    <div key={index} className={styles['row-input-wrapper']}>
      <div className={styles['row-input']}>
        <div className={styles['row-input-row']}>
          {index === 0 &&
            questionSet.questions.map((q, k1) => (
              <div className={styles['row-input-element']} key={k1}>
                {q.label}
              </div>
            ))}
        </div>
        <div key={index} className={styles['row-input-row']}>
          {questionSet.questions.map((q, k) => {
            if (q.type === QS_CONSTANTS.QUESTION_TYPE.QUESTION) {
              const value = Data.getAnswerWithPath(path, q.variable)
              const inputType = q.inputType
              const variable = q.variable
              const renderOptions = {
                questionSet,
                vocabularies,
                question: q,
                value,
                index: k,
                path,
                disabled,
                Component: Widgets[inputType],
                Data
              }
              let Question = null
              switch (inputType) {
                case INPUT_TYPE.TEXT:
                  Question = <TextInput {...renderOptions} />
                  break
                case INPUT_TYPE.DATE:
                  Question = <DatePicker {...renderOptions} />
                  break
                case INPUT_TYPE.TIME:
                  Question = <TimePicker {...renderOptions} />
                  break
                // case INPUT_TYPE.SELECT:
                //   Question = this.renderSelectInput(renderOptions)
                //   break
                case INPUT_TYPE.RADIO:
                  Question = (
                    <RadioInput
                      {...renderOptions}
                      textValue={Data.getAnswerWithPath(path, `${variable}-other`)}
                    />
                  )
                  break
                // case INPUT_TYPE.CHECKBOX:
                //   Question = this.renderCheckBoxInput(renderOptions)
                //   break
                // case INPUT_TYPE.SLIDER:
                //   Question = this.renderSlider(renderOptions)
                //   break
                // case INPUT_TYPE.CHECKBOX_IMAGE:
                //   Question = this.renderCheckBoxImageInput(renderOptions)
                //   break
                // case INPUT_TYPE.INLINE_RADIO:
                //   Question = this.renderInlineRadioInput(renderOptions)
                //   break
                default:
                  break
              }
              return (
                <div key={k} className={styles['row-input-element']}>
                  {Question}
                </div>
              )
            }
            return null
          })}
        </div>
      </div>
    </div>
  )
}

QuestionSetToRow.propTypes = {
  path: PropType.array,
  index: PropType.number,
  questionSet: PropType.object,
  vocabularies: PropType.array,
  disabled: PropType.bool
}

export default QuestionSetToRow
