import Immutable from 'immutable'

import {
  GET_PROJECT,
  GET_PROJECT_FULFILLED,
  GET_PROJECT_REJECTED,
  GET_PROJECTS,
  GET_PROJECTS_FULFILLED,
  GET_PROJECTS_REJECTED
  // CREATE_PROJECT,
  // CREATE_PROJECT_FULFILLED,
  // CREATE_PROJECT_REJECTED,
  // UPDATE_PROJECT,
  // UPDATE_PROJECT_FULFILLED,
  // UPDATE_PROJECT_REJECTED,
  // DELETE_PROJECT,
  // DELETE_PROJECT_FULFILLED,
  // DELETE_PROJECT_REJECTED
} from '@/store/types'
// import { replaceArrayItem, removeArrayItem } from '@/services/utils/array'
import { STORE } from '@/constants/strings'

const DEFAULT_STATE = {
  [STORE.DATA]: null,
  [STORE.ERROR]: null,
  [STORE.IS_PENDING]: false
}

const initialState = new Immutable.Map({
  ...DEFAULT_STATE,
  [STORE.CURRENT_PROJECT]: {
    ...DEFAULT_STATE
  }
})

export default {
  initialState,
  handlers: {
    [GET_PROJECT]: (state) =>
      state.merge({
        [STORE.CURRENT_PROJECT]: {
          ...DEFAULT_STATE,
          [STORE.IS_PENDING]: true
        }
      }),
    [GET_PROJECT_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_PROJECT]: {
          ...DEFAULT_STATE,
          [STORE.IS_PENDING]: false,
          [STORE.DATA]: action.payload
        }
      }),
    [GET_PROJECT_REJECTED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_PROJECT]: {
          ...DEFAULT_STATE,
          [STORE.IS_PENDING]: false,
          [STORE.ERROR]: action.payload
        }
      }),
    [GET_PROJECTS]: (state) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.IS_PENDING]: true
      }),
    [GET_PROJECTS_FULFILLED]: (state, action) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.IS_PENDING]: false,
        [STORE.DATA]: action.payload
      }),
    [GET_PROJECTS_REJECTED]: (state, action) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.IS_PENDING]: false,
        [STORE.ERROR]: action.payload
      })
    //   [CREATE_PROJECT]: (state) =>
    //     state.merge({
    //       projects: { ...state.get('projects'), isPending: true }
    //     }),
    //   [CREATE_PROJECT_FULFILLED]: (state, action) =>
    //     state.merge({
    //       projects: {
    //         data: [action.payload, ...state.get('projects').data],
    //         isPending: false,
    //         error: null
    //       }
    //     }),
    //   [CREATE_PROJECT_REJECTED]: (state, action) =>
    //     state.merge({
    //       projects: {
    //         ...state.get('projects'),
    //         isPending: false,
    //         error: action.payload
    //       }
    //     }),
    //   [UPDATE_PROJECT]: (state) =>
    //     state.merge({
    //       projects: { ...state.get('projects'), isPending: true }
    //     }),
    //   [UPDATE_PROJECT_FULFILLED]: (state, action) =>
    //     state.merge({
    //       projects: {
    //         data: replaceArrayItem(action.payload, state.get('projects').data),
    //         isPending: false,
    //         error: null
    //       }
    //     }),
    //   [UPDATE_PROJECT_REJECTED]: (state, action) =>
    //     state.merge({
    //       projects: {
    //         ...state.get('projects'),
    //         isPending: false,
    //         error: action.payload
    //       }
    //     }),
    //   [DELETE_PROJECT]: (state) =>
    //     state.merge({
    //       projects: { ...state.get('projects'), isPending: true }
    //     }),
    //   [DELETE_PROJECT_FULFILLED]: (state, action) =>
    //     state.merge({
    //       projects: {
    //         data: removeArrayItem(action.payload, state.get('projects').data),
    //         isPending: false,
    //         error: null
    //       }
    //     }),
    //   [DELETE_PROJECT_REJECTED]: (state, action) =>
    //     state.merge({
    //       projects: {
    //         ...state.get('projects'),
    //         isPending: false,
    //         error: action.payload
    //       }
    //     })
  }
}
