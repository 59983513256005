import React, { lazy, Suspense, useEffect, useState } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import AuthRoute from '@/routes/auth'
import FormEngineDemo from '@/views/form-engine-demo'
import Loading from '@/shared/components/loading'
import authEpics from '@/store/epics/auth'
import { epic$ } from '@/store/epics'

const HomeView = lazy(() => import('@/views/home'))
const ProjectView = lazy(() => import('@/views/project'))
const LoginView = lazy(() => import('@/views/login'))

export const ROUTES = {
  ALL: '*',
  HOME: '/home',
  LOGIN: '/login',
  PROJECT: '/projects/:projectId',
  FORM_ENGINE_DEMO: '/form-engine-demo'
}

const RootRoutes = () => {
  const [isReady, setIsReady] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    epic$.next(authEpics.introspectEpic)
    dispatch(authEpics.introspect())
    setIsReady(true)
  }, [])
  if (!isReady) {
    return null
  } else {
    return (
      <HashRouter>
        <Suspense fallback={<Loading fullscreen={true} />}>
          <Switch>
            <AuthRoute path={ROUTES.HOME} component={HomeView} />
            <AuthRoute path={ROUTES.PROJECT} component={ProjectView} />
            <AuthRoute path={ROUTES.FORM_ENGINE_DEMO} component={FormEngineDemo} />
            <Route path={ROUTES.LOGIN} component={LoginView} />
            <Route path={ROUTES.ALL}>
              <Redirect to={ROUTES.HOME} />
            </Route>
          </Switch>
        </Suspense>
      </HashRouter>
    )
  }
}

export default RootRoutes
