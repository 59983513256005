import React, { useContext } from 'react'
import PropType from 'prop-types'
import { range, cloneDeep } from 'lodash'

import { DataContext } from '../../context'
import { getQuestionSetLabel } from '../../utils'
import { QUESTION_TYPE, QS_CONSTANTS } from '../../constants'
import QuestionSetToRow from '../question-set-to-row'
import Question from '../question'
import styles from '../../styles/index.module.less'
import Video from '../../components/video'

const QuestionSet = (props) => {
  const { index, path, questionSet, vocabularies, disabled } = props

  const Data = useContext(DataContext)

  const renderQuestionSet = (index, path, questionSet) => {
    const questionSetLabel = getQuestionSetLabel(index, path, questionSet)
    const ifQuestionSetDisplay = Data.checkIfQuestionDisplay(path, questionSet)
    const questionSetAnswer = Data.getAnswerWithPath(
      path.slice(0, path.length - 1),
      path.slice(path.length - 1)
    )

    const isQuestionSetDisplay = Object.keys(questionSetAnswer).reduce((acc, cur, idx, arr) => {
      if (acc) {
        arr.splice(arr.length)
        return true
      } else {
        return !questionSetAnswer[cur].isHidden
      }
    }, false)

    if (ifQuestionSetDisplay && isQuestionSetDisplay) {
      const { name, description } = questionSet
      const videoId = questionSet.video?.id
      return (
        <div key={index} name={questionSet.name} className={styles['question-set']}>
          <div className={styles.title}>{questionSetLabel}</div>
          {/*Display video if video field is set*/}
          {videoId && <Video videoId={videoId} />}

          {description && description.length > 0 && (
            <div className={styles.subtitle}>
              {description.map((p, index) => (
                <p key={index}>{p}</p>
              ))}
            </div>
          )}

          <div className={styles.section} id={name} key={index}>
            {questionSet.questions.map((question, index) => {
              if (question.type === QUESTION_TYPE.QUESTION) {
                return (
                  <Question
                    key={index}
                    index={index}
                    path={path}
                    question={question}
                    questionSet={questionSet}
                    vocabularies={vocabularies}
                  />
                )
              } else if (
                question.type === QUESTION_TYPE.QUESTION_SET &&
                question.display &&
                question.display === QS_CONSTANTS.REPEAT_SET
              ) {
                const reference = question.reference
                const paths = Data.getDominantPath(path)
                let num = 0
                paths.forEach((p) => {
                  const answer = Data.getAnswerWithPath(p, reference)
                  if (!isNaN(parseInt(answer))) {
                    num = parseInt(answer)
                  }
                })
                const newPath = [...path]
                newPath.push(question.name)
                return range(0, num).map((k) => {
                  const pathWithIndex = [...newPath]
                  pathWithIndex.push(k)
                  if (question.format && question.format === QS_CONSTANTS.QUESTION_FORMAT.ROW) {
                    return (
                      <QuestionSetToRow
                        path={pathWithIndex}
                        index={k}
                        question={question}
                        disabled={disabled}
                      />
                    )
                  }
                  return renderQuestionSet(k, pathWithIndex, question)
                })
              } else if (
                question.type === QUESTION_TYPE.QUESTION_SET &&
                question.alignment === 'TABLE'
              ) {
                const paths = cloneDeep(path)
                paths.push(question.name)
                // return this.renderTableQuestions(k, paths, q, fieldDisabled)
              } else if (question.type === QUESTION_TYPE.QUESTION_SET) {
                const paths = cloneDeep(path)
                paths.push(question.name)
                return renderQuestionSet(index, paths, question)
              }
              return null
            })}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  return renderQuestionSet(index, path, questionSet)
}

QuestionSet.propTypes = {
  path: PropType.array,
  answers: PropType.object,
  index: PropType.number,
  questionSet: PropType.object,
  vocabularies: PropType.array,
  disabled: PropType.bool
}

export default QuestionSet
