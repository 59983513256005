import React from 'react'
import PropType from 'prop-types'
import classNames from 'classnames'
import { cloneDeep, get, isEqual, set } from 'lodash'

import { INPUT_TYPE } from '../../constants'

const TextInput = (props) => {
  const { questionSet, question, value, path, disabled, Component, Data } = props
  const { variable, canFormulaCollapse } = question
  const isFormula = question.formula
  const componentDisabled = (isFormula && !value.isCollapsed) || disabled
  const collapseQuestion = (e) => {
    e.preventDefault()
    const newValue = cloneDeep(value)
    let answers = cloneDeep(Data.answers)
    const variablePaths = Data.getFormulaVariables(question.formula)
    variablePaths.forEach((path) => {
      const answer = get(answers, path)
      answer.isHidden = !newValue.isCollapsed
      answers = set(answers, path, answer)
    })
    newValue.isCollapsed = !newValue.isCollapsed
    answers = set(answers, [...path, question.variable], newValue)
    Data.generateData({ answers })
  }
  return (
    <>
      <Component
        disabled={componentDisabled}
        name={variable}
        onChange={(e) =>
          Data.handleChange(
            e,
            variable,
            questionSet.name,
            INPUT_TYPE.TEXT,
            questionSet,
            question,
            path
          )
        }
        type='text'
        value={value}
      />
      {isFormula && canFormulaCollapse ? (
        <button
          className={classNames({
            textInput__collapseBtn: true,
            hidden: disabled
          })}
          onClick={collapseQuestion}
        >
          {value.isCollapsed ? 'Expand' : 'Collapse'} sub-questions
        </button>
      ) : null}
    </>
  )
}

TextInput.propTypes = {
  value: PropType.object,
  Data: PropType.object,
  path: PropType.array,
  question: PropType.object,
  questionSet: PropType.object,
  Component: PropType.oneOfType([PropType.func, PropType.object]),
  disabled: PropType.bool
}

const areEqual = (prevProps, props) => {
  const areEqual = isEqual(prevProps.value, props.value) && !!prevProps.disable === !!props.disable
  return areEqual
}

export default React.memo(TextInput, areEqual)
