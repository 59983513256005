import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { from, iif, of } from 'rxjs'
import { ofType } from 'redux-observable'

import {
  CREATE_QUESTIONNAIRE,
  CREATE_QUESTIONNAIRE_FULFILLED,
  CREATE_QUESTIONNAIRE_REJECTED,
  DELETE_QUESTIONNAIRE,
  DELETE_QUESTIONNAIRE_FULFILLED,
  DELETE_QUESTIONNAIRE_REJECTED,
  GET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_FULFILLED,
  GET_QUESTIONNAIRE_REJECTED,
  GET_QUESTIONNAIRE_UNDER_TASK,
  GET_QUESTIONNAIRE_UNDER_TASK_FULFILLED,
  GET_QUESTIONNAIRE_UNDER_TASK_REJECTED,
  GET_QUESTIONNAIRES,
  GET_QUESTIONNAIRES_FULFILLED,
  GET_QUESTIONNAIRES_REJECTED,
  UPDATE_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE_FULFILLED,
  UPDATE_QUESTIONNAIRE_REJECTED
} from '@/store/types'
import {
  createQuestionnaire,
  deleteQuestionnaire,
  getQuestionnaire,
  getQuestionnaires,
  updateQuestionnaire
} from '@/services/api'

const questionnairesEpics = {
  getQuestionnaire: ({ questionnaireId }) => ({
    type: GET_QUESTIONNAIRE,
    payload: { questionnaireId }
  }),

  getQuestionnaireFulfilled: (payload) => ({
    type: GET_QUESTIONNAIRE_FULFILLED,
    payload
  }),

  getQuestionnaireRejected: (error) => ({
    type: GET_QUESTIONNAIRE_REJECTED,
    payload: error
  }),

  getQuestionnaireEpic: (action$) =>
    action$.pipe(
      ofType(GET_QUESTIONNAIRE),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(getQuestionnaire(action.payload)).pipe(
          map(questionnairesEpics.getQuestionnaireFulfilled),
          catchError((error) => of(questionnairesEpics.getQuestionnaireRejected(error)))
        )
      )
    ),

  getQuestionnaires: ({ projectId, taskId }) => ({
    type: GET_QUESTIONNAIRES,
    payload: { projectId, taskId }
  }),

  getQuestionnairesFulfilled: (payload) => ({
    type: GET_QUESTIONNAIRES_FULFILLED,
    payload
  }),

  getQuestionnairesRejected: (error) => ({
    type: GET_QUESTIONNAIRES_REJECTED,
    payload: error
  }),

  getQuestionnairesEpic: (action$) =>
    action$.pipe(
      ofType(GET_QUESTIONNAIRES),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(getQuestionnaires(action.payload)).pipe(
          map(questionnairesEpics.getQuestionnairesFulfilled),
          catchError((error) => of(questionnairesEpics.getQuestionnairesRejected(error)))
        )
      )
    ),

  createQuestionnaire: ({ projectId, questionnaire, taskId }) => ({
    type: CREATE_QUESTIONNAIRE,
    payload: { projectId, questionnaire, taskId }
  }),

  createQuestionnaireFulfilled: (payload) => ({
    type: CREATE_QUESTIONNAIRE_FULFILLED,
    payload
  }),

  createQuestionnaireRejected: (error) => ({
    type: CREATE_QUESTIONNAIRE_REJECTED,
    payload: error
  }),

  createQuestionnaireEpic: (action$) =>
    action$.pipe(
      ofType(CREATE_QUESTIONNAIRE),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(createQuestionnaire(action.payload)).pipe(
          map(questionnairesEpics.createQuestionnaireFulfilled),
          catchError((error) => of(questionnairesEpics.createQuestionnaireRejected(error)))
        )
      )
    ),

  updateQuestionnaire: ({ questionnaireId, questionnaire }) => ({
    type: UPDATE_QUESTIONNAIRE,
    payload: { questionnaireId, questionnaire }
  }),

  updateQuestionnaireFulfilled: (payload) => ({
    type: UPDATE_QUESTIONNAIRE_FULFILLED,
    payload
  }),

  updateQuestionnaireRejected: (error) => ({
    type: UPDATE_QUESTIONNAIRE_REJECTED,
    payload: error
  }),

  updateQuestionnaireEpic: (action$) =>
    action$.pipe(
      ofType(UPDATE_QUESTIONNAIRE),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(updateQuestionnaire(action.payload)).pipe(
          map(questionnairesEpics.updateQuestionnaireFulfilled),
          catchError((error) => of(questionnairesEpics.updateQuestionnaireRejected(error)))
        )
      )
    ),

  deleteQuestionnaire: ({ projectId, questionnaireId, taskId }) => ({
    type: DELETE_QUESTIONNAIRE,
    payload: { projectId, questionnaireId, taskId }
  }),

  deleteQuestionnaireFulfilled: (payload) => ({
    type: DELETE_QUESTIONNAIRE_FULFILLED,
    payload
  }),

  deleteQuestionnaireRejected: (error) => ({
    type: DELETE_QUESTIONNAIRE_REJECTED,
    payload: error
  }),

  deleteQuestionnaireEpic: (action$) =>
    action$.pipe(
      ofType(DELETE_QUESTIONNAIRE),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(deleteQuestionnaire(action.payload)).pipe(
          map(questionnairesEpics.deleteQuestionnaireFulfilled),
          catchError((error) => of(questionnairesEpics.deleteQuestionnaireRejected(error)))
        )
      )
    ),

  getQuestionnaireUnderTask: ({ projectId, taskId }) => ({
    type: GET_QUESTIONNAIRE_UNDER_TASK,
    payload: { projectId, taskId }
  }),

  getQuestionnaireUnderTaskFulfilled: (payload) => ({
    type: GET_QUESTIONNAIRE_UNDER_TASK_FULFILLED,
    payload
  }),

  getQuestionnaireUnderTaskRejected: (error) => ({
    type: GET_QUESTIONNAIRE_UNDER_TASK_REJECTED,
    payload: error
  }),

  getQuestionnaireUnderTaskEpic: (action$) =>
    action$.pipe(
      ofType(GET_QUESTIONNAIRE_UNDER_TASK),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(getQuestionnaires(action.payload)).pipe(
          mergeMap((questionnaires) =>
            iif(
              () => questionnaires.length === 0,
              of({}).pipe(
                mergeMap(() =>
                  from(
                    createQuestionnaire({
                      ...action.payload,
                      questionnaire: {}
                    })
                  )
                )
              ),
              of(questionnaires.sort((q1, q2) => q2.createdAt - q1.createdAt)[0])
            )
          ),
          map(questionnairesEpics.getQuestionnaireUnderTaskFulfilled),
          catchError((error) => of(questionnairesEpics.getQuestionnaireUnderTaskRejected(error)))
        )
      )
    )
}

export default questionnairesEpics
