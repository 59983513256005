import Immutable from 'immutable'

import {
  GET_ALL_ANSWERS_UNDER_TASKS,
  GET_ALL_ANSWERS_UNDER_TASKS_FULFILLED,
  GET_ALL_ANSWERS_UNDER_TASKS_REJECTED,
  GET_ANSWERS,
  GET_ANSWERS_FULFILLED,
  GET_ANSWERS_REJECTED,
  GET_CURRENT_ANSWER,
  GET_CURRENT_ANSWER_FULFILLED,
  GET_CURRENT_ANSWER_REJECTED,
  UPDATE_ANSWER,
  UPDATE_ANSWER_FULFILLED,
  UPDATE_ANSWER_REJECTED
} from '@/store/types'
import { STORE } from '@/constants/strings'

const DEFAULT_STATE = {
  [STORE.DATA]: null,
  [STORE.ERROR]: null,
  [STORE.IS_PENDING]: false
}

const initialState = new Immutable.Map({
  ...DEFAULT_STATE,
  [STORE.CURRENT_ANSWER]: { ...DEFAULT_STATE },
  [STORE.ANSWERS_UNDER_TASKS]: { ...DEFAULT_STATE }
})

export default {
  initialState,
  handlers: {
    // [GET_ANSWER]: (state) =>
    //   state.merge({
    //     [STORE.IS_PENDING]: true
    //   }),
    // [GET_ANSWER_FULFILLED]: (state, action) =>
    //   state.merge({
    //     [STORE.IS_PENDING]: false,
    //     [STORE.DATA]: action.payload,
    //     [STORE.ERROR]: null
    //   }),
    // [GET_ANSWER_REJECTED]: (state, action) =>
    //   state.merge({
    //     [STORE.IS_PENDING]: false,
    //     [STORE.DATA]: null,
    //     [STORE.ERROR]: action.payload
    //   }),
    [GET_ANSWERS]: (state) => state.merge({ ...DEFAULT_STATE, [STORE.IS_PENDING]: true }),
    [GET_ANSWERS_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.IS_PENDING]: false,
        [STORE.DATA]: action.payload,
        [STORE.ERROR]: null
      }),
    [GET_ANSWERS_REJECTED]: (state, action) =>
      state.merge({
        [STORE.IS_PENDING]: false,
        [STORE.DATA]: null,
        [STORE.ERROR]: action.payload
      }),
    // [CREATE_ANSWER]: (state) =>
    //   state.merge({
    //     [STORE.ANSWERS]: { ...state.get(STORE.ANSWERS), [STORE.IS_PENDING]: true }
    //   }),
    // [CREATE_ANSWER_FULFILLED]: (state, action) =>
    //   state.merge({
    //     [STORE.ANSWERS]: {
    //       [STORE.DATA]: [action.payload, ...state.get(STORE.ANSWERS)[STORE.DATA]],
    //       [STORE.IS_PENDING]: false,
    //       [STORE.ERROR]: null
    //     }
    //   }),
    // [CREATE_ANSWER_REJECTED]: (state, action) =>
    //   state.merge({
    //     [STORE.ANSWERS]: {
    //       ...state.get(STORE.ANSWERS),
    //       [STORE.IS_PENDING]: false,
    //       [STORE.ERROR]: action.payload
    //     }
    //   }),
    [UPDATE_ANSWER]: (state) => state,
    [UPDATE_ANSWER_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_ANSWER]: {
          [STORE.DATA]: action.payload,
          [STORE.ERROR]: null,
          [STORE.IS_PENDING]: false
        }
      }),
    [UPDATE_ANSWER_REJECTED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_ANSWER]: {
          [STORE.DATA]: null,
          [STORE.ERROR]: action.payload,
          [STORE.IS_PENDING]: false
        }
      }),
    // [DELETE_ANSWER]: (state) =>
    //   state.merge({
    //     [STORE.ANSWERS]: { ...state.get(STORE.ANSWERS), [STORE.IS_PENDING]: true }
    //   }),
    // [DELETE_ANSWER_FULFILLED]: (state, action) =>
    //   state.merge({
    //     [STORE.ANSWERS]: {
    //       [STORE.DATA]: removeArrayItem(action.payload, state.get(STORE.ANSWERS)[STORE.DATA]),
    //       [STORE.IS_PENDING]: false,
    //       [STORE.ERROR]: null
    //     }
    //   }),
    // [DELETE_ANSWER_REJECTED]: (state, action) =>
    //   state.merge({
    //     [STORE.ANSWERS]: {
    //       ...state.get(STORE.ANSWERS),
    //       [STORE.IS_PENDING]: false,
    //       [STORE.ERROR]: action.payload
    //     }
    //   }),

    [GET_CURRENT_ANSWER]: (state) =>
      state.merge({
        [STORE.CURRENT_ANSWER]: {
          ...DEFAULT_STATE,
          [STORE.IS_PENDING]: true
        }
      }),
    [GET_CURRENT_ANSWER_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_ANSWER]: {
          [STORE.DATA]: action.payload,
          [STORE.ERROR]: null,
          [STORE.IS_PENDING]: false
        }
      }),
    [GET_CURRENT_ANSWER_REJECTED]: (state, action) =>
      state.merge({
        [STORE.CURRENT_ANSWER]: {
          [STORE.DATA]: null,
          [STORE.ERROR]: action.payload,
          [STORE.IS_PENDING]: false
        }
      }),
    [GET_ALL_ANSWERS_UNDER_TASKS]: (state) =>
      state.merge({
        [STORE.ANSWERS_UNDER_TASKS]: {
          ...DEFAULT_STATE,
          [STORE.IS_PENDING]: true
        }
      }),
    [GET_ALL_ANSWERS_UNDER_TASKS_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.ANSWERS_UNDER_TASKS]: {
          [STORE.DATA]: action.payload,
          [STORE.ERROR]: null,
          [STORE.IS_PENDING]: false
        }
      }),
    [GET_ALL_ANSWERS_UNDER_TASKS_REJECTED]: (state, action) =>
      state.merge({
        [STORE.ANSWERS_UNDER_TASKS]: {
          [STORE.DATA]: null,
          [STORE.ERROR]: action.payload,
          [STORE.IS_PENDING]: false
        }
      })
  }
}
