const QS_CONSTANTS = {
  AUTHORIZED: 'authorized',
  BUSINESS_RULE: 'businessRule',
  CHANGE_OPTIONS: 'change options',
  CREATE_VALUE: 'Other (Specify)',
  DISPLAY: 'display',
  FORMAT: 'format',
  INVALID_CSS: 'valid',
  INPUT_TYPE: {
    CHECKBOX: 'checkbox',
    CHECKBOX_IMAGE: 'checkboxWithImage',
    DATE: 'date',
    INLINE_RADIO: 'inlineRadio',
    INPUT_TYPE: 'inputType',
    RADIO: 'radio',
    SELECT: 'select',
    SLIDER: 'slider',
    TEXT: 'text',
    TIME: 'time'
  },
  MULTIPLICATION: 'multiplication',
  SHOW_IF: 'showIf',
  OTHER_ANSWER: 'Other (specify)',
  OPTIONS: 'options',
  QUESTION_TYPE: {
    QUESTION: 'QUESTION',
    QUESTION_SET: 'QUESTION_SET'
  },
  QUESTION_FORMAT: {
    ROW: 'row'
  },
  REPEAT_SET: 'repeat set',
  RULE: 'rule',
  TEXT_VALIDATION: 'text validation',
  USER_TYPES: {
    ADMIN: 'ADMIN',
    ANYONE: 'ANYONE',
    CODER_A: 'CODER_A',
    CODER_B: 'CODER_B',
    USER: 'USER'
  }
}
const INPUT_TYPE = {
  CHECKBOX: 'checkbox',
  CHECKBOX_IMAGE: 'checkboxWithImage',
  DATE: 'date',
  INLINE_RADIO: 'inlineRadio',
  INPUT_TYPE: 'inputType',
  RADIO: 'radio',
  SELECT: 'select',
  SLIDER: 'slider',
  TEXT: 'text',
  TIME: 'time'
}
const QUESTION_TYPE = {
  QUESTION: 'QUESTION',
  QUESTION_SET: 'QUESTION_SET'
}

const WIDGETS = {
  CHECKBOX_WIDGET: 'CheckboxInput',
  DATE_PICKER_WIDGET: 'DatePicker',
  RADIO_INPUT_WIDGET: 'RadioInput',
  TEXT_INPUT_WIDGET: 'TextInput',
  TIME_INPUT_WIDGET: 'TimePicker'
}
const INPUT_TYPE_WIDGET_MAPPING = {
  [INPUT_TYPE.CHECKBOX]: WIDGETS.CHECKBOX_WIDGET,
  [INPUT_TYPE.DATE]: WIDGETS.DATE_PICKER_WIDGET,
  [INPUT_TYPE.RADIO]: WIDGETS.RADIO_INPUT_WIDGET,
  [INPUT_TYPE.TEXT]: WIDGETS.TEXT_INPUT_WIDGET,
  [INPUT_TYPE.TIME]: WIDGETS.TIME_INPUT_WIDGET
}

const LAYOUT_MODE = {
  DYNAMIC: 'dynamic',
  STATIC: 'static'
}

export { QS_CONSTANTS, INPUT_TYPE, INPUT_TYPE_WIDGET_MAPPING, WIDGETS, LAYOUT_MODE, QUESTION_TYPE }
