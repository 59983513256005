import DayPickerInput from 'react-day-picker/DayPickerInput'
import PropTypes from 'prop-types'
import React from 'react'
import { formatDate, parseDate } from 'react-day-picker/moment'
// import moment from 'moment'

import 'react-day-picker/lib/style.css'

const genNewVal = (date, format, oldVal) => {
  const newValue = {
    value: formatDate(date)
  }
  return { ...oldVal, ...newValue }
}

const DayPicker = (props) => {
  const { disabled, format, placeholder } = props
  const value = props.value.value ? new Date(props.value.value) : ''
  const onChange = (date) => {
    const newVal = genNewVal(date, format, props.value)
    props.onChange(newVal)
  }

  const onDayPickerShow = () => {
    if (value === '') {
      const date = new Date()
      const newVal = genNewVal(date, format, props.value)
      props.onChange(newVal)
    }
  }
  return (
    <div>
      <DayPickerInput
        onDayChange={onChange}
        value={value}
        format={format}
        placeholder={placeholder}
        formatDate={formatDate}
        parseDate={parseDate}
        inputProps={{ disabled }}
        onDayPickerShow={onDayPickerShow}
      />
    </div>
  )
}

DayPicker.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object.isRequired
}

export default DayPicker
