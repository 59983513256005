import Immutable from 'immutable'

import {
  GET_DEFENCE_ELEMENTS,
  GET_DEFENCE_ELEMENTS_FULFILLED,
  GET_DEFENCE_ELEMENTS_REJECTED
} from '@/store/types'
import { STORE } from '@/constants/strings'

const DEFAULT_STATE = {
  [STORE.DATA]: null,
  [STORE.ERROR]: null,
  [STORE.IS_PENDING]: false
}

const initialState = new Immutable.Map({
  ...DEFAULT_STATE
})

export default {
  initialState,
  handlers: {
    [GET_DEFENCE_ELEMENTS]: (state) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.IS_PENDING]: true
      }),
    [GET_DEFENCE_ELEMENTS_FULFILLED]: (state, action) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.IS_PENDING]: false,
        [STORE.DATA]: action.payload
      }),
    [GET_DEFENCE_ELEMENTS_REJECTED]: (state, action) =>
      state.merge({
        ...DEFAULT_STATE,
        [STORE.IS_PENDING]: false,
        [STORE.ERROR]: action.payload
      })
  }
}
