import { BehaviorSubject, iif, EMPTY } from 'rxjs'
import { combineEpics } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'

const epicRegistry = []
export const epic$ = new BehaviorSubject(combineEpics(...epicRegistry))

const rootEpic = (action$, state$) =>
  epic$.pipe(
    mergeMap((epic) =>
      iif(
        () => {
          const isExist = epicRegistry.includes(epic)
          if (!isExist) {
            epicRegistry.push(epic)
          }
          return isExist
        },
        EMPTY,
        epic(action$, state$)
      )
    )
  )

export default rootEpic
