import React from 'react'
import RcTimePicker from 'rc-time-picker'
import moment from 'moment'
import PropTypes from 'prop-types'

const genNewVal = (time, format, oldVal) => {
  const newVal = {
    value: ''
  }
  if (time) {
    newVal.value = time.format(format)
  }
  return { ...oldVal, ...newVal }
}

const TimePicker = (props) => {
  const { disabled, placeholder } = props
  const format = props.format || 'HH:mm:ss'
  const value = moment(props.value.value, format)
  const isValueValid = value.isValid()
  const onChange = (time) => {
    const newVal = genNewVal(time, format, props.value)
    props.onChange(newVal)
  }
  const onOpen = () => {
    if (!value.isValid()) {
      const newVal = genNewVal(moment(), format, props.value)
      props.onChange(newVal)
    }
  }
  return (
    <RcTimePicker
      disabled={disabled}
      onChange={onChange}
      onOpen={onOpen}
      placeholder={placeholder}
      value={isValueValid ? value : null}
    />
  )
}

TimePicker.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.object.isRequired
}

export default TimePicker
