import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler } from './utils'

/**
 * Get a defence element
 * @param {string} DefenceElementId: uuid that referring a single defence element
 */
const getDefenceElement = async ({ defenceElementId }) => {
  try {
    const response = await API.get(ENDPOINTS.DEFENCE_ELEMENT({ defenceElementId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get all defence elements
 */
const getDefenceElements = async () => {
  try {
    const response = await API.get(ENDPOINTS.DEFENCE_ELEMENTS)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * create a defence element
 * @param {object} DefenceElement: the structure of the defence element
 */
const createDefenceElement = async ({ defenceElement }) => {
  try {
    const response = await API.post(ENDPOINTS.DEFENCE_ELEMENTS, defenceElement)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * update a defence element
 * @param {string} defenceElementId: uuid that referring a single defence element
 * @param {object} defenceElement: the updated structure of the defence element
 */
const updateDefenceElement = async ({ defenceElementId, defenceElement }) => {
  try {
    const response = await API.put(ENDPOINTS.DEFENCE_ELEMENT({ defenceElementId }), defenceElement)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * delete a defence element
 * @param {string} defenceElementId: uuid that referring a single defence element
 */
const deleteDefenceElement = async ({ defenceElementId }) => {
  try {
    const response = await API.delete(ENDPOINTS.DEFENCE_ELEMENT({ defenceElementId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}
export {
  getDefenceElement,
  getDefenceElements,
  createDefenceElement,
  updateDefenceElement,
  deleteDefenceElement
}
