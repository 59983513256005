import { cloneDeep, get } from 'lodash'
import { evaluate } from 'mathjs'

import { QUESTION_TYPE } from '../constants'

/**
 * Retrieve all possible question set's layers that can affect showIf condition.
 * @param {Array} path path to affected question.
 * (Only direct ancestors' value can dominate the value of the children. Nodes on the same level can not affect current value. )
 */
const getDominantPath = (path, questionSet = this.questionSet) => {
  const dominantPaths = []
  const currentPath = cloneDeep(path)
  let i = path.length
  for (i; i > 0; i--) {
    const pos = i - 1
    if (typeof currentPath[pos] !== 'undefined') {
      if (typeof currentPath[pos] === 'number') {
        dominantPaths.push([...currentPath])
        currentPath.splice(-2, 2)
      } else {
        dominantPaths.push([...currentPath])
        currentPath.splice(-1, 1)
      }
    }
  }
  // support cross question set (only first layer.)
  questionSet.questions.forEach((questionSet) => {
    dominantPaths.push([questionSet.name])
  })
  return dominantPaths
}

const getFormulaVariables = (formula) => {
  const fn = new Function(`
      const getFormulaVariables = (strings, ...variables) => variables
      return getFormulaVariables\`${formula}\`
    `)
  return fn()
}

const getFormulaVariableValue = (path, answers) => get(answers, path)

const getFormulaResult = (formula, values) => {
  const fn = new Function(`
      const ttl = (strings, ...keys) => {
        return function (collection) {
          let result = [strings[0]]
          collection = collection || {}
          keys.forEach(function (key, i) {
            result.push(collection[key], strings[i + 1])
          })
          return result.join('')
        }
      }
      const fn = ttl\`${formula}\`
      return fn
      `)
  return evaluate(fn()(values))
}

const getAllQuestions = (questionSet) => {
  const questions = {}
  const getQuestions = (questionSet, path) => {
    let newPath = []
    if (path) {
      newPath = [...path, questionSet.name]
    }
    questionSet.questions.forEach((question) => {
      const { type } = question
      if (type === QUESTION_TYPE.QUESTION) {
        questions[question._id] = {
          question,
          path: newPath,
          questionSet
        }
      } else {
        getQuestions(question, newPath)
      }
    })
  }

  getQuestions(questionSet)
  return questions
}

export {
  getDominantPath,
  getFormulaResult,
  getFormulaVariableValue,
  getFormulaVariables,
  getAllQuestions
}
