export const GET_PROJECT = 'GET_PROJECT'
export const GET_PROJECT_FULFILLED = 'GET_PROJECT_FULFILLED'
export const GET_PROJECT_REJECTED = 'GET_PROJECT_REJECTED'

export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_PROJECTS_FULFILLED = 'GET_PROJECTS_FULFILLED'
export const GET_PROJECTS_REJECTED = 'GET_PROJECTS_REJECTED'

export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_FULFILLED = 'CREATE_PROJECT_FULFILLED'
export const CREATE_PROJECT_REJECTED = 'CREATE_PROJECT_REJECTED'

export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATE_PROJECT_FULFILLED = 'UPDATE_PROJECT_FULFILLED'
export const UPDATE_PROJECT_REJECTED = 'UPDATE_PROJECT_REJECTED'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_FULFILLED = 'DELETE_PROJECT_FULFILLED'
export const DELETE_PROJECT_REJECTED = 'DELETE_PROJECT_REJECTED'
