import { cloneDeep, get, isEqual, isEmpty, reduce, set, isObject, isNaN } from 'lodash'
import moment from 'moment'

import { UPDATE_VALIDATIONS, UPDATE_PROGRESS } from '../reducer/types'
import { QS_CONSTANTS, QUESTION_TYPE, INPUT_TYPE } from '../constants'
import { initialAnswer } from './answer'
import {
  getFormulaVariableValue,
  getFormulaVariables,
  getFormulaResult,
  getAllQuestions
} from './utils'

class Data {
  constructor({
    answers,
    questionSet,
    dispatch,
    onAnswersChange,
    onProgressChange,
    onQuestionComponentsChange
  }) {
    const _self = this
    const initialAnswers = cloneDeep(answers)
    const initialQuestionSet = cloneDeep(questionSet)
    _self.answers = initialAnswers
    _self.questionSet = initialQuestionSet
    _self.dispatch = dispatch
    _self.progress = {}
    _self.touchedQuestionPath = []
    _self.questionComponents = []

    _self.checkIfQuestionDisplay = _self.checkIfQuestionDisplay.bind(_self)
    _self.dispatchProgress = _self.dispatchProgress.bind(_self)
    _self.generateData = _self.generateData.bind(_self)
    _self.getAllQuestions = getAllQuestions
    _self.getAnswerWithPath = _self.getAnswerWithPath.bind(_self)
    _self.getDominantPath = _self.getDominantPath.bind(_self)
    _self.getFormulaVariables = getFormulaVariables
    _self.getOption = _self.getOption.bind(_self)
    _self.getValidationWithPath = _self.getValidationWithPath.bind(_self)
    _self.handleCheckboxInput = _self.handleCheckboxInput.bind(_self)
    _self.handleRadioSelect = _self.handleRadioSelect.bind(_self)
    _self.handleTimePickerChange = _self.handleTimePickerChange.bind(_self)
    _self.onAnswersChange = onAnswersChange
    _self.onProgressChange = onProgressChange
    _self.onQuestionComponentsChange = onQuestionComponentsChange
    _self.resetAnswer = _self.resetAnswer.bind(_self)
    _self.setAnswer = _self.setAnswer.bind(_self)
    _self.setAnswers = _self.setAnswers.bind(_self)
    _self.setQuestionComponent = _self.setQuestionComponent.bind(_self)
    _self.setQuestionSet = _self.setQuestionSet.bind(_self)
    _self.setRadioAnswer = _self.setRadioAnswer.bind(_self)
    _self.setSliderAnswer = _self.setSliderAnswer.bind(_self)
    _self.updateProgress = _self.updateProgress.bind(_self)
  }

  /**
   *  Change answer with the path to the answer.
   * @param {String} name variableName
   * @param {String} value the value of answer to be updated.
   * @param {Array} path the path to the position in question set.
   * @return {Object} Answer Object
   */
  setAnswer(name, value, path, answers = this.answers) {
    const state = cloneDeep(answers)
    const answer = path.reduce((o, i) => o[i], state)
    answer[name] = value
    return state
  }

  setAnswers(answers) {
    const _self = this
    if (!isEqual(answers, _self.answers)) {
      _self.answers = answers
      _self.dispatchProgress()
      _self.onAnswersChange(answers)
    }
  }

  setQuestionComponent(component, id) {
    const _self = this
    _self.questionComponents[id] = component
    _self.onQuestionComponentsChange(_self.questionComponents)
  }

  /**
   * Change answer of a checkbox input type question with the path to the answer.
   * @param {String} name variableName
   * @param {String} value the value of answer to be updated.
   * @param {Array} path the path to the position in question set.
   * @return {Object} Answer Object
   */
  setCheckboxAnswer(name, value, path, answers = this.answers) {
    const state = cloneDeep(answers)
    const answer = path.reduce((o, i) => o[i], state)
    const position = answer[name].indexOf(value)
    if (position > -1) {
      answer[name].splice(position, 1)
    } else {
      answer[name].push(value)
    }
    return state
  }

  /**
   * Function to change Change answer with the path to the answer when the slider value changes.
   * @param {String} name variableName
   * @param {String} value the value of answer to be updated.
   * @param {Array} path the path to the position in question set.
   * @return {Object} Answer Object
   */
  setSliderAnswer(name, value, path, answers = this.answers) {
    const state = cloneDeep(answers)
    const answer = path.reduce((o, i) => o[i], state)
    answer[name] = value
    return state
  }

  setRadioAnswer(name, value, path, answers = this.answers) {
    const state = cloneDeep(answers)
    const textInRadio = `${name}-other`
    const answer = path.reduce((o, i) => o[i], state)
    answer[name] = value
    if (value !== QS_CONSTANTS.CREATE_VALUE && answer[textInRadio]) {
      delete answer[textInRadio]
    }
    return state
  }

  setQuestionSet(questionSet) {
    const _self = this
    _self.questionSet = questionSet
  }

  setProgress(progress) {
    const _self = this
    _self.progress = progress
    if (_self.dispatch) {
      _self.dispatch({ type: UPDATE_PROGRESS, payload: progress })
    }
  }

  setValidations(validations) {
    const _self = this
    _self.validations = validations
    if (_self.dispatch) {
      _self.dispatch({ type: UPDATE_VALIDATIONS, payload: validations })
    }
  }

  /**
   * Check if newly updated answer fit any business rule
   * @param {Array} path path to answer
   * @param {Object} q current question
   * @param {Object} questionSetInAnswer
   */
  checkBusinessRule(path, q, questionSetInAnswer, answers = this.answers) {
    const _self = this
    const variable = q.variable
    const ifQuestionDisplay = _self.checkIfQuestionDisplay(path, q)
    if (q.businessRule) {
      const businessRule = q.businessRule
      if (businessRule.rule === QS_CONSTANTS.MULTIPLICATION) {
        const values = businessRule.reference.map((eachVariable) =>
          parseInt(_self.getAnswerWithPath(path, eachVariable, answers))
        )
        let result = values.reduce((a, b) => a * b)
        if (isNaN(result)) {
          result = 0
        }
        questionSetInAnswer[q.variable] = {
          value: result
        }
      }
    }
    // this part handles when a question's status changed from 'show' to 'hidden' because of showIf rule.
    // it's previous value should be set back to ''.
    if (q.showIf) {
      if (!ifQuestionDisplay) {
        questionSetInAnswer[variable] = {
          value: ''
        }
        // delete progress[path + variable]
      } else {
        // if variable needs to be displayed but there is no answer record, add it.
        if (Object.keys(questionSetInAnswer).indexOf(variable) < 0) {
          if (q.inputType === INPUT_TYPE.CHECKBOX) {
            questionSetInAnswer[q.variable] = {
              value: []
            }
          } else {
            questionSetInAnswer[q.variable] = {
              value: ''
            }
          }
        }
      }
    }
    if (q.formula && ifQuestionDisplay) {
      const { formula } = q
      const value = _self.getAnswerWithPath(path, q.variable, answers)
      if (!value.isCollapsed) {
        const variablePaths = getFormulaVariables(formula)
        const values = reduce(
          variablePaths,
          (result, path) => {
            const variableValue = getFormulaVariableValue(path, answers)
            const value = isObject(variableValue) ? Number(variableValue.value) : 0
            result[path] = isNaN(value) ? 0 : value
            return result
          },
          {}
        )
        const result = getFormulaResult(formula, values).toString()
        if (result !== value) {
          questionSetInAnswer[q.variable] = {
            value: result
          }
        }
      }
    }
  }

  checkIfQuestionDisplay(path, question) {
    const _self = this
    const showIfConditions = question.showIf
    if (showIfConditions && showIfConditions.length !== 0) {
      return showIfConditions.reduce((prev, condition) => {
        const targetValue = condition.value
        const targetVariable = condition.variable
        const dominatePaths = _self.getDominantPath(path)
        const isSatisfied = dominatePaths.reduce((prev, curr) => {
          const checkedAnswer = _self.getAnswerWithPath(curr, targetVariable).value
          let isEqual
          if (condition.comparator && checkedAnswer) {
            let compareValueWithFormat = checkedAnswer
            let targetValueWithFormat = targetValue
            if (condition.formatter) {
              if (condition.formatter === 'AGE') {
                compareValueWithFormat = moment().diff(moment(checkedAnswer), 'years')
              } else if (condition.formatter === 'DATE') {
                compareValueWithFormat = moment(checkedAnswer).toDate()
                targetValueWithFormat = moment(targetValue).toDate()
              }
              //TODO more formatter can added in later
            }
            if (condition.comparator === 'GREATER_THAN') {
              isEqual = compareValueWithFormat > targetValueWithFormat
            } else if (condition.comparator === 'LESS_THAN') {
              isEqual = compareValueWithFormat < targetValueWithFormat
            } else if (condition.comparator === 'GREATER_THAN_AND_LESS_THAN') {
              let splitTarget = targetValueWithFormat.split(' ')
              let targetGreaterThan = splitTarget[0],
                targetLesserThan = splitTarget[1]
              isEqual =
                targetGreaterThan < compareValueWithFormat &&
                targetLesserThan > compareValueWithFormat
            }
          } else {
            // multiple answers are stored in an array
            if (Array.isArray(checkedAnswer)) {
              isEqual = checkedAnswer.indexOf(targetValue) > -1
            } else {
              isEqual = checkedAnswer === targetValue
            }
          }
          return prev || isEqual
        }, false)
        // let actualValue = this.getAnswerWithPath(path, targetVariable);
        return prev || isSatisfied
      }, false)
    }
    return true
  }

  /**
   * Update progress record
   * 1. construct progress object,
   * 2. send progress object to redux.
   */
  dispatchProgress() {
    const _self = this
    const progressToDispatch = {}
    const progress = Object.assign({}, _self.progress)
    Object.keys(progress).forEach((eachProgressKey) => {
      const setName = eachProgressKey.split(',')[0]
      const { isRequired, value, isValid, ignore } = progress[eachProgressKey]
      if (ignore) {
        return
      }
      if (Object.keys(progressToDispatch).indexOf(setName) < 0) {
        progressToDispatch[setName] = {
          total: 0,
          totalRequired: 0,
          answered: 0,
          answeredRequired: 0,
          invalid: 0
        }
      }
      // update total quesiton num
      progressToDispatch[setName].total++
      // update total answered num
      if (isValid && value.value.length !== 0) {
        progressToDispatch[setName].answered++
      }
      // update total required num
      if (isRequired) {
        progressToDispatch[setName].totalRequired++
        // update required answer num
        if (isValid && value.value.length !== 0) {
          progressToDispatch[setName].answeredRequired++
        }
      }

      if (!isValid) {
        progressToDispatch[setName].invalid++
      }
    })
    if (_self.onProgressChange) {
      _self.onProgressChange(progressToDispatch)
    }
  }

  generateData(options) {
    const _self = this
    const answers = cloneDeep(options?.answers || _self.answers)
    const onAnswersChange = options?.onAnswersChange
    const validations = {}
    const questionSet = cloneDeep(options?.questionSet || _self.questionSet)
    const openList = []

    questionSet.questions.forEach((each) => {
      openList.push([each, []])
    })
    while (openList.length !== 0) {
      // q: question, could either be a question or a question set
      // path: the path to the answer of this question/set.
      const [q, path] = openList.pop()
      // questionSetInAnswer => Answer object of a question set in the answer tree.
      // it looks like { variable1: answer1 , variable2: answer2, sub-repeated-question-set: [ {sub-variable: xx}, {sub-variable: xx}, {sub-variable3: xx}]}
      let questionSetInAnswer = path.reduce((k, v) => k[v], answers)
      let questionSetInValidation = path.reduce((k, v) => k[v], validations)
      if (!questionSetInAnswer) {
        answers[q.name] = {}
        questionSetInAnswer = answers[q.name]
      }
      if (!questionSetInValidation) {
        validations[q.name] = {}
        questionSetInValidation = validations[q.name]
      }
      // initial answers when there is no answer record in answers. change {} to {variable1: 'None', variable2: 'None'}
      initialAnswer(questionSetInAnswer, q)
      if (q.type && q.type === QUESTION_TYPE.QUESTION_SET) {
        questionSetInValidation[q.name] = {}
        const ifQuestionSetDisplay = _self.checkIfQuestionDisplay(path, q)
        if (ifQuestionSetDisplay) {
          q.questions.forEach((question) => {
            const newPath = [...path]
            newPath.push(q.name)
            openList.push([question, newPath])
          })
        } else {
          questionSetInAnswer[q.name] = {}
        }
      } else if (q.type && q.type === QUESTION_TYPE.QUESTION) {
        // check business rule.
        _self.checkBusinessRule(path, q, questionSetInAnswer, answers)
        // validation
        if (q.validation) {
          const { inputType } = q
          let answer = questionSetInAnswer[q.variable]
          if (inputType === INPUT_TYPE.RADIO && answer.value === QS_CONSTANTS.CREATE_VALUE) {
            answer = questionSetInAnswer[`${q.variable}-other`] || { value: '' }
          }
          if (_self.touchedQuestionPath.includes(`${path.toString()},${q.variable}`)) {
            questionSetInValidation[q.variable] = _self.validateAnswer(q.validation, answer)
          }
        }
        _self.updateProgress(path, q, questionSetInValidation, questionSetInAnswer)
      }
    }
    _self.setAnswers(answers, onAnswersChange)
    _self.setValidations(validations)
  }

  getAnswerWithPath(path, variable, answers = this.answers) {
    let answer = null
    try {
      answer = path.reduce((o, i) => o[i], answers)
    } catch (e) {
      return {
        value: ''
      }
    }
    return answer ? (answer[variable] ? answer[variable] : { value: '' }) : { value: '' }
  }

  /**
   * Retrieve all possible question set's layers that can affect showIf condition.
   * @param {Array} path path to affected question.
   * (Only direct ancestors' value can dominate the value of the children. Nodes on the same level can not affect current value. )
   */
  getDominantPath = (path, questionSet = this.questionSet) => {
    const dominantPaths = []
    const currentPath = cloneDeep(path)
    let i = path.length
    for (i; i > 0; i--) {
      const pos = i - 1
      if (typeof currentPath[pos] !== 'undefined') {
        if (typeof currentPath[pos] === 'number') {
          dominantPaths.push([...currentPath])
          currentPath.splice(-2, 2)
        } else {
          dominantPaths.push([...currentPath])
          currentPath.splice(-1, 1)
        }
      }
    }
    // support cross question set (only first layer.)
    questionSet.questions.forEach((questionSet) => {
      dominantPaths.push([questionSet.name])
    })
    return dominantPaths
  }

  /**
   * Get dropdown options from question object.
   * @param {Object} question Question to be displayed.
   * @param {Array} path Path to current question set.
   * @returns {String} the ID of the options that is going to be displayed.
   */
  getOption(question, path) {
    const _self = this
    const businessRule = question.businessRule
    if (businessRule && businessRule.rule === QS_CONSTANTS.CHANGE_OPTIONS) {
      const rules = businessRule.changeRules
      let changeToOption = null
      rules.forEach((rule) => {
        const targetVariable = rule.variable
        const targetValue = rule.value
        const actualValue = _self.getAnswerWithPath(path, targetVariable)
        if (Array.isArray(actualValue)) {
          if (actualValue.indexOf(targetValue) > -1) {
            changeToOption = rule.options
          }
        } else if (targetValue === actualValue) {
          changeToOption = rule.options
        }
      })
      if (changeToOption) {
        return changeToOption
      }
      return question.options
    }
    return question.options
  }

  getValidationWithPath(path, variable, validations = this.validations) {
    return get(validations, [...path, variable])
  }

  /**
   * Handle form change event. it does following things
   * 1. update value change to the state (user answers a question)
   * 2. check business rule in setState callback. if the change fit any rule
   * 3. apply business rule,
   * 4. check if a text area needs to be appear or hidden.
   * 5. update the progress of the data input tin redux store.
   *
   * @param {Object} evt object
   * @param {String} variable name
   * @param {String} questionSetName question set name
   * @param {String} inputType
   * @param {Object} questionSet
   */
  handleChange(evt, variable, questionSetName, inputType, questionSet, question, path, isForce) {
    const _self = this
    if (
      inputType === QS_CONSTANTS.INPUT_TYPE.TEXT &&
      !_self.isPassPercentValidation(evt.target.value, question)
    ) {
      return
    }
    if (!isForce) {
      const touchedPath = `${path.toString()},${variable}`
      if (!_self.touchedQuestionPath.includes(touchedPath)) {
        _self.touchedQuestionPath.push(touchedPath)
      }
    }

    // update the value of the target input field.
    let newAnswer = {}
    if (inputType === INPUT_TYPE.SLIDER) {
      newAnswer = _self.setSliderAnswer(evt.target.name, evt.target.value, path)
    } else {
      newAnswer = _self.setAnswer(evt.target.name, evt.target.value, path)
    }
    _self.generateData({ answers: newAnswer })
    _self.onAnswersChange(newAnswer)
  }

  /**
   * Handle date picker input
   * @param option
   * @param variable
   * @param questionSetName
   * @param inputType
   * @param questionSet
   * @param question
   * @param path
   */
  handleDatePickerChange(
    option,
    variable,
    questionSetName,
    inputType,
    questionSet,
    question,
    path
  ) {
    const _self = this
    const NewEvt = {}
    NewEvt.target = {}
    NewEvt.target.value = option
    NewEvt.target.name = variable
    _self.handleChange(NewEvt, variable, questionSetName, inputType, questionSet, question, path)
  }

  /**
   * Handle Radio-select change before real handleChange() as React-select does not provide evt object.
   * @param {String} option
   * @param {String} optionsID
   * @param {String} variable
   * @param {String} questionSetName
   * @param {String} inputType
   * @param {Object} questionSet
   * @param {Object} question
   * @param {Array} path
   */
  handleRadioSelect(
    option,
    optionsID,
    variable,
    questionSetName,
    inputType,
    questionSet,
    question,
    path
  ) {
    const _self = this
    const NewEvt = {}
    NewEvt.target = {}
    NewEvt.target.value = option
    NewEvt.target.name = variable
    _self.handleChange(NewEvt, variable, questionSetName, inputType, questionSet, question, path)
  }

  /**
   * HandleCheckboxInput before handleChange
   * @param option
   * @param optionsID
   * @param variable
   * @param questionSetName
   * @param inputType
   * @param questionSet
   * @param question
   * @param path
   */
  handleCheckboxInput(
    option,
    optionsID,
    variable,
    questionSetName,
    inputType,
    questionSet,
    question,
    path
  ) {
    const _self = this
    const NewEvt = {}
    NewEvt.target = {}
    NewEvt.target.value = option
    NewEvt.target.name = variable
    _self.handleChange(NewEvt, variable, questionSetName, inputType, questionSet, question, path)
  }

  /**
   * Handle time picker input
   * @param time
   * @param variable
   * @param questionSetName
   * @param inputType
   * @param questionSet
   * @param question
   * @param path
   */
  handleTimePickerChange(
    option,
    variable,
    questionSetName,
    inputType,
    questionSet,
    question,
    path
  ) {
    const _self = this
    const NewEvt = {}
    NewEvt.target = {}
    NewEvt.target.value = option
    NewEvt.target.name = variable
    _self.handleChange(NewEvt, variable, questionSetName, inputType, questionSet, question, path)
  }

  /**
   * validate if a give value fits the percent validation
   * @param value
   * @param question
   * @returns {boolean}
   */
  isPassPercentValidation(value, question) {
    const _self = this
    if (
      question.validation &&
      question.validation.percent &&
      question.validation.percent.required
    ) {
      const max = 100
      const min = 0
      return _self.isWithBoundary(max, min, value)
    }
    return true
  }

  /**
   * Check if give value is within a given boundary. return false to indicates it is not.
   * @param {Number} max
   * @param {Number} min
   * @param {String} value
   * @returns {boolean}
   */
  isWithBoundary(max, min, value) {
    return !(isNaN(value) || Number(value) < min || Number(value) > max)
  }

  /**
   * Reset input answer to empty string
   * @param {*} name answer variable
   * @param {*} path answer path
   */
  resetAnswer(name, path) {
    const _self = this

    if (isEmpty(_self.answers)) {
      return [_self.answers, false]
    }
    let answers = cloneDeep(_self.answers)
    const answer = get(answers, path)
    const isChanged = answer && answer[name].value !== ''
    if (isChanged) {
      answers = set(answers, [...path, name], { ...answer[name], value: '' })
      _self.onAnswersChange(answers)
    }
  }

  /**
   * Update Progress record in local variable
   * @param {Array} path
   * @param {Object} q
   * @param {Object} questionSetInAnswer
   */
  updateProgress(path, q, questionSetInValidation, questionSetInAnswer) {
    const _self = this
    const variable = q.variable
    const valueRecord = {
      isValid: !questionSetInValidation[variable],
      value: questionSetInAnswer[variable],
      isRequired: q.validation ? q.validation.isRequired : false,
      ignore: !!q.formula || questionSetInAnswer[variable].isHidden
    }
    const pathToValue = `${path},${variable}`
    _self.progress[pathToValue] = valueRecord
    if (q.showIf) {
      const ifQuestionDisplay = _self.checkIfQuestionDisplay(path, q)
      if (!ifQuestionDisplay) {
        delete _self.progress[pathToValue]
        _self.resetAnswer(variable, path)
      }
    }
  }

  validateAnswer(validation, answer) {
    const { value } = answer
    const { isRequired, maxLength, minLength, number, match } = validation
    const isMaxLengthRequired = maxLength.required
    const maxLengthValue =
      maxLength.value && maxLength.value !== '' ? Number(maxLength.value) : null
    const isMinLengthRequired = minLength.required
    const minLengthValue =
      minLength.value && minLength.value !== '' ? Number(minLength.value) : null
    const isNumberValidationRequired = number.required
    const minNumber =
      number.minValue !== undefined && number.minValue !== '' ? Number(number.minValue) : null
    const maxNumber =
      number.maxValue !== undefined && number.maxValue !== '' ? Number(number.maxValue) : null

    let error
    if (isRequired && (value === '' || (Array.isArray(value) && value.length === 0))) {
      error = ' IS A REQUIRED FIELD.'
    } else if (!(value === '' || (Array.isArray(value) && value.length === 0))) {
      if (isMaxLengthRequired && maxLengthValue && value.length > maxLengthValue) {
        error = ` MAX LENGTH IS ${maxLengthValue}`
      } else if (isMinLengthRequired && minLengthValue && value.length < minLengthValue) {
        error = ` MIN LENGTH IS ${minLengthValue}`
      } else if (isNumberValidationRequired && isNaN(Number(value))) {
        error = ' SHOULD BE NUMBER'
      } else if (isNumberValidationRequired && minNumber !== null && Number(value) < minNumber) {
        error = ` SHOULD BE GREATER THAN OR EQUAL TO ${minNumber}`
      } else if (isNumberValidationRequired && maxNumber !== null && Number(value) > maxNumber) {
        error = ` SHOULD BE LESS THAN OR EQUAL TO ${maxNumber}`
      } else if (match && match.required) {
        const regex = new RegExp(match.value)
        if (!value.match(regex)) {
          error = match.errorMessage
        }
      }
    }
    return error
  }
}

export default Data
