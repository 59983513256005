/**
 * Avatar encapsulation
 *
 * based on https://avataaars.com/ and https://github.com/fangpenlin/avataaars
 */
import {
  DEFAULT,
  accessoriesTypes,
  clotheColors,
  clotheTypes,
  facialHairTypes,
  hairColors,
  // skinColors,
  topTypes
} from './constants'

import Avatar from 'avataaars'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { uhash } from '@/lib/util'

export const Wrapper = styled.div`
  line-height: 1px;
  g[fill='#65C9FF'] {
    fill: #cccccc !important; // hack the background for now to avoid chromophobic
  }
`

const AvatarComponent = (props) => {
  const {
    size = '32px',
    user: { username }
  } = props

  // Default to make sure no surprising expressions
  const eyeType = DEFAULT
  const eyebrowType = DEFAULT
  const mouthType = DEFAULT
  const avatarStyle = 'Circle'
  const skinColor = 'Light'

  // Chop hash value into various bit segments that can be mapped to avataaars' options
  const chop = (options, val) => {
    const shift = Math.ceil(Math.log2(options.length))
    const ander = 2 ** shift - 1
    const res = val & ander
    val = val >> shift
    return [val, res]
  }
  const pick = (options, offset) => options[offset % options.length]
  let val = uhash(username) // use unsigned hash

  let res
  ;[val, res] = chop(topTypes, val) // use semicolon for destructuring hinting
  const topType = pick(topTypes, res)
  ;[val, res] = chop(accessoriesTypes, val)
  const accessoriesType = pick(accessoriesTypes, res)
  ;[val, res] = chop(hairColors, val)
  const hairColor = pick(hairColors, res)
  ;[val, res] = chop(facialHairTypes, val)
  const facialHairType = pick(facialHairTypes, res)
  ;[val, res] = chop(clotheTypes, val)
  const clotheType = pick(clotheTypes, res)
  ;[val, res] = chop(clotheColors, val)
  const clotheColor = pick(clotheColors, res)
  // ;[val, res] = chop(skinColors, val)
  // const skinColor = pick(skinColors, res)

  return (
    <Wrapper>
      <Avatar
        style={{ width: size, height: size }}
        {...{
          avatarStyle,
          topType,
          accessoriesType,
          hairColor,
          facialHairType,
          clotheType,
          clotheColor,
          eyeType,
          eyebrowType,
          mouthType,
          skinColor
        }}
      />
    </Wrapper>
  )
}

AvatarComponent.propTypes = {
  /** User object that may contain username */
  user: PropTypes.object.isRequired,
  /** Size of the avatars (e.g. 48px) */
  size: PropTypes.string
}
export default AvatarComponent
