import React, { useEffect, useState } from 'react'
import { Wrapper } from './styles'
import MonacoEditor from 'react-monaco-editor/lib/editor'
import PropTypes from 'prop-types'
import useDebounce from '@/components/json-converter/hooks/useDebounce'
const JsonEditor = (props) => {
  const { defaultObj, onObjectChange, onErrorChange } = props

  const [code, setCode] = useState(typeof defaultObj === 'object' ? JSON.stringify(defaultObj) : '')

  const debouncedCode = useDebounce(code, 500)

  /**
   * Every time when is code(debounced) is updated, try parsing to json object, otherwise, set error
   */
  useEffect(() => {
    try {
      const parsedObj = JSON.parse(debouncedCode)
      onObjectChange(parsedObj)
      onErrorChange(null)
    } catch (e) {
      onErrorChange(e)
    }
  }, [debouncedCode, onObjectChange, onErrorChange])

  // eslint-disable-next-line no-unused-vars
  const editorDidMount = (editor, monaco) => {
    editor.focus()
    setTimeout(() => {
      editor.getAction('editor.action.formatDocument').run()
    }, 300)
  }

  const options = {
    selectOnLineNumbers: true
  }

  return (
    <Wrapper>
      <MonacoEditor
        language='json'
        theme='vs-dark'
        value={code}
        options={options}
        onChange={setCode}
        editorDidMount={editorDidMount}
      />
    </Wrapper>
  )
}

JsonEditor.propTypes = {
  defaultObj: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onObjectChange: PropTypes.func,
  onErrorChange: PropTypes.func
}

export default JsonEditor
