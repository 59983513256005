import React, { useContext, useCallback } from 'react'
import PropType from 'prop-types'
import ReactTooltip from 'react-tooltip'

import CheckboxInput from '../checkbox-input'
import DatePicker from '../date-picker'
import RadioInput from '../radio-input'
import TextInput from '../text-input'
import TimePicker from '../time-picker'
import Timer from '../timer'
import styles from '../../styles/index.module.less'
import { DataContext, WidgetsContext } from '../../context'
import { INPUT_TYPE } from '../../constants'
import { isQuestionRequired } from '../../utils'

const Question = (props) => {
  const Widgets = useContext(WidgetsContext) || props.widgets
  const Data = useContext(DataContext) || props.data

  const { enableTimer, path, question, questionSet, vocabularies, index } = props
  const { description, label, inputType, variable } = question
  const value = Data.getAnswerWithPath(path, variable)
  const validation = Data.getValidationWithPath(path, variable)
  const errorMessage = validation
  const hasTimer = enableTimer && question.expiresIn

  const hasExpireTime = !!value.expireTime
  const ifQuestionDisplay = Data.checkIfQuestionDisplay(path, question) && !value.isHidden
  const disabled = props.disabled || (hasExpireTime ? value.expireTime < Date.now() : false)
  const isErrorMsgShow = errorMessage

  const renderOptions = {
    questionSet,
    vocabularies,
    question,
    value,
    index,
    path,
    disabled,
    Component: Widgets[inputType],
    Data
  }

  const onReady = (expireTime) => {
    if (!expireTime) {
      return
    }
    const answer = Data.getAnswerWithPath(path, variable)
    const newAnswer = {
      ...answer,
      ...{
        expireTime
      }
    }
    let newAnswers

    if (inputType === INPUT_TYPE.CHECKBOX) {
      newAnswers = Data.setCheckboxAnswer(variable, newAnswer, path)
    } else if (inputType === INPUT_TYPE.SLIDER) {
      newAnswers = Data.setSliderAnswer(variable, newAnswer, path)
    } else {
      newAnswers = Data.setAnswer(variable, newAnswer, path)
    }
    Data.generateData({ answers: newAnswers })
    Data.onAnswersChange(newAnswers)
  }

  const onComplete = () => {
    // _self.forceUpdate()
  }

  const getQuestionComponent = useCallback(() => {
    let Question = null
    switch (inputType) {
      case INPUT_TYPE.TEXT:
        Question = <TextInput {...renderOptions} />
        break
      case INPUT_TYPE.DATE:
        Question = <DatePicker {...renderOptions} />
        break
      case INPUT_TYPE.TIME:
        Question = <TimePicker {...renderOptions} />
        break
      // case INPUT_TYPE.SELECT:
      //   Question = this.renderSelectInput(renderOptions)
      //   break
      case INPUT_TYPE.RADIO:
        Question = (
          <RadioInput
            {...renderOptions}
            textValue={Data.getAnswerWithPath(path, `${variable}-other`)}
          />
        )
        break
      case INPUT_TYPE.CHECKBOX:
        Question = (
          <CheckboxInput
            {...renderOptions}
            textValue={Data.getAnswerWithPath(path, `${variable}-other`)}
          />
        )
        break
      // case INPUT_TYPE.SLIDER:
      //   Question = this.renderSlider(renderOptions)
      //   break
      // case INPUT_TYPE.CHECKBOX_IMAGE:
      //   Question = this.renderCheckBoxImageInput(renderOptions)
      //   break
      // case INPUT_TYPE.INLINE_RADIO:
      //   Question = this.renderInlineRadioInput(renderOptions)
      //   break
      default:
        break
    }
    return Question
  }, [inputType, renderOptions, Data, path, variable])

  // render question if current answers satisfy its showIf condition.
  if (ifQuestionDisplay) {
    const Question = getQuestionComponent()
    return (
      <div key={index} className={`${styles.question} ${styles['form-group']}`}>
        {label && (
          <label>
            <span>
              {index + 1}. {label}
            </span>
            {description && (
              <span>
                <a
                  className={styles.help}
                  data-tip={description}
                  data-for={`question-description-${variable}`}
                >
                  <i className='fa fa-question-circle' />
                </a>
              </span>
            )}
            {isQuestionRequired(question) && (
              <span className={styles.required} title='Required'>
                *
              </span>
            )}
          </label>
        )}
        {description && (
          <ReactTooltip
            id={`question-description-${variable}`}
            class={styles['tool-tip-window']}
            aria-haspopup='true'
            place='right'
            effect='solid'
          />
        )}
        <div className={styles.marginTop}>{Question}</div>
        <div hidden={!isErrorMsgShow} className={styles.error}>
          {errorMessage}
        </div>
        {hasTimer &&
          (hasExpireTime ? (
            <Timer
              label={`Question ${index + 1}`}
              expireTime={value.expireTime}
              onReady={onReady}
              onComplete={onComplete}
            />
          ) : (
            <Timer
              expireInSeconds={question.expiresIn}
              label={`Question ${index + 1}`}
              onReady={onReady}
              onComplete={onComplete}
            />
          ))}
      </div>
    )
  }
  return null
}

Question.propTypes = {
  index: PropType.number,
  path: PropType.array,
  question: PropType.object,
  questionSet: PropType.object,
  vocabularies: PropType.array,
  enableTimer: PropType.bool,
  disabled: PropType.bool,
  data: PropType.object,
  widgets: PropType.object,
  layoutMode: PropType.string
}

export default Question
