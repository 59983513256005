import { connect } from 'react-redux'
import FormEngine from '@/components/form-engine'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './styles'
import { Spin, Alert } from 'antd'
import styles from './styles/index.module.less'
import questionnairesEpics from '@/store/epics/questionnaires'
import { epic$ } from '@/store/epics'
import { STORE } from '@/constants/strings'

const QuestionsForm = (props) => {
  // determine if the questionnaire is loaded directly from object or from api
  const loadFromDefaultQuestionnaire =
    props.defaultQuestionnaire !== undefined && props.defaultQuestionnaire !== null

  // fetch the questionnaire from api if necessary
  useEffect(() => {
    if (!loadFromDefaultQuestionnaire) {
      epic$.next(questionnairesEpics.getQuestionnaireEpic)
      props.getQuestionnaire({ projectId: props.projectId, questionnaireId: props.questionnaireId })
    }
  }, [])

  // eslint-disable-next-line no-unused-vars
  const onFormAnswersChange = (answers) => {}
  // eslint-disable-next-line no-unused-vars
  const onFormProgressChange = (progress) => {}

  const getForm = () => {
    if (loadFromDefaultQuestionnaire) {
      return (
        <FormEngine
          questionSet={props.defaultQuestionnaire.questionSet}
          vocabularies={props.defaultQuestionnaire.options}
          onAnswersChange={onFormAnswersChange}
          onProgressChange={onFormProgressChange}
        />
      )
    } else if (props.isPending) {
      return <Spin size='large' className={styles.spin} />
    } else if (props.error !== null) {
      return <Alert message='Error' description={props.error.message} type='error' showIcon />
    } else {
      return (
        <React.Fragment>
          {props.questionnaire !== null ? (
            <FormEngine
              questionSet={props.questionnaire.questionSet}
              vocabularies={props.questionnaire.options}
              onAnswersChange={onFormAnswersChange}
              onProgressChange={onFormProgressChange}
            />
          ) : null}
        </React.Fragment>
      )
    }
  }

  return <Wrapper>{getForm()}</Wrapper>
}

QuestionsForm.propTypes = {
  defaultQuestionnaire: PropTypes.object,
  projectId: PropTypes.string,
  questionnaireId: PropTypes.string,

  error: PropTypes.object,
  questionnaire: PropTypes.object,
  isPending: PropTypes.bool,

  getQuestionnaire: PropTypes.func
}

const mapStateToProps = (state) => ({
  error: state.getIn([STORE.QUESTIONNAIRES, STORE.ERROR]),
  questionnaire: state.getIn([STORE.QUESTIONNAIRES, STORE.DATA]),
  isPending: state.getIn([STORE.QUESTIONNAIRES, STORE.IS_PENDING])
})

const mapDispatchToProps = {
  getQuestionnaire: questionnairesEpics.getQuestionnaire
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsForm)
