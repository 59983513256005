import axios from 'axios'

import { refresh } from './auth'
import { ROUTES } from '@/routes/root'
import { history } from '@/store'
import store, { LOCAL_STORAGE_KEYS } from '@/services/local-storage'

const unauthorizedInterceptor = (instance) =>
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config && error.response && error.response.status === 401) {
        try {
          await refresh()
          const { accessToken, tokenType } = await store.getItem(LOCAL_STORAGE_KEYS.CREDENTIAL)
          error.config.headers.Authorization = `${tokenType} ${accessToken}`
          return axios.request(error.config)
        } catch (err) {
          if (err.response.status === 401) {
            store.clear()
            history.push(ROUTES.LOGIN)
          }
        }
      }
      return Promise.reject(error)
    }
  )
const extractResponse = (res) => res.data

const errorHandler = (err) => {
  if (err.response) {
    const { message, status, code } = err.response.data
    const newError = new Error(message || status)
    newError.status = status
    newError.code = code
    throw newError
  } else {
    throw err
  }
}

export { errorHandler, extractResponse, unauthorizedInterceptor }
