/**
 * Convert string to signed hash
 *
 * @param {string} text String input for hash
 * @returns {number} 32-bit signed hash
 */
export const hash = (text) => {
  let hash = 0
  const len = text.length
  if (!len) {
    return hash
  }
  for (let i = 0; i < len; i++) {
    let char = text.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

/**
 * Convert string to unsigned hash
 *
 * @param {string} text String input for hash
 * @returns {number} 32-bit unsigned hash
 *
 * https://github.com/darkskyapp/string-hash/blob/master/index.js
 */
export const uhash = (text) => {
  let hash = 5381
  let i = text.length

  while (i) {
    hash = (hash * 33) ^ text.charCodeAt(--i)
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0
}
