import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler } from './utils'

/**
 * Get a comment under a task
 * @param {string} taskId: uuid that referring a single task under the project
 * @param {string} commentId: uuid that referring a single comment under the project
 */
const getTaskComment = async ({ taskId, commentId }) => {
  try {
    const response = await API.get(ENDPOINTS.TASK_COMMENT({ taskId, commentId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get all comments under a task
 * @param {string} taskId: uuid that referring a single task under the project
 */
const getTaskComments = async ({ taskId }) => {
  try {
    const response = await API.get(ENDPOINTS.TASK_COMMENTS({ taskId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * create a comment under a task
 * @param {string} taskId: uuid that referring a single task under the project
 * @param {object} comment: the structure of the task comment
 */
const createTaskComment = async ({ taskId, comment }) => {
  try {
    const response = await API.post(ENDPOINTS.TASK_COMMENTS({ taskId }), comment)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * update a comment under a task
 * @param {string} taskId: uuid that referring a single task under the project
 * @param {string} commentId: uuid that referring a single comment under the task
 * @param {object} comment: the updated structure of the task comment
 */
const updateTaskComment = async ({ taskId, commentId, comment }) => {
  try {
    const response = await API.put(ENDPOINTS.TASK_COMMENT({ taskId, commentId }), comment)
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * delete a task comment
 * @param {string} taskId: uuid that referring a single task under the project
 * @param {string} commentId: uuid that referring a single comment under the task
 */
const deleteTaskComment = async ({ taskId, commentId }) => {
  try {
    const response = await API.delete(ENDPOINTS.TASK_COMMENT({ taskId, commentId }))
    return response.data.data
  } catch (err) {
    errorHandler(err)
  }
}

export { getTaskComment, getTaskComments, createTaskComment, updateTaskComment, deleteTaskComment }
