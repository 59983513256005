import Immutable from 'immutable'

import {
  INTROSPECT,
  INTROSPECT_FULFILLED,
  INTROSPECT_REJECTED,
  LOGIN,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED
} from '@/store/types'
import { STORE } from '@/constants/strings'

const initialState = new Immutable.Map({
  [STORE.ERROR]: null,
  [STORE.IS_AUTHORIZED]: false,
  [STORE.IS_LOGGING_OUT]: false,
  [STORE.IS_VERIFYING]: false,
  [STORE.USER]: null
})

export default {
  initialState,
  handlers: {
    [INTROSPECT]: (state) =>
      state.merge({
        [STORE.ERROR]: null,
        [STORE.IS_VERIFYING]: true
      }),
    [INTROSPECT_FULFILLED]: (state, action) =>
      state.merge({
        [STORE.IS_AUTHORIZED]: true,
        [STORE.IS_VERIFYING]: false,
        [STORE.USER]: action.payload
      }),
    [INTROSPECT_REJECTED]: (state) =>
      state.merge({
        [STORE.IS_AUTHORIZED]: false,
        [STORE.IS_VERIFYING]: false,
        [STORE.USER]: null
      }),
    [LOGIN]: (state) =>
      state.merge({
        [STORE.ERROR]: null,
        [STORE.IS_VERIFYING]: true
      }),
    [LOGIN_FULFILLED]: (state) =>
      state.merge({
        [STORE.IS_AUTHORIZED]: true,
        [STORE.IS_VERIFYING]: false
      }),
    [LOGIN_REJECTED]: (state, action) =>
      state.merge({
        [STORE.ERROR]: action.payload,
        [STORE.IS_AUTHORIZED]: false,
        [STORE.IS_VERIFYING]: false
      }),
    [LOGOUT]: (state) =>
      state.merge({
        [STORE.ERROR]: null,
        [STORE.IS_LOGGING_OUT]: true
      }),
    [LOGOUT_FULFILLED]: (state) =>
      state.merge({
        [STORE.IS_AUTHORIZED]: false,
        [STORE.IS_LOGGING_OUT]: false,
        [STORE.USER]: null
      }),
    [LOGOUT_REJECTED]: (state, action) =>
      state.merge({
        [STORE.ERROR]: action.payload,
        [STORE.IS_AUTHORIZED]: false,
        [STORE.IS_LOGGING_OUT]: false
      })
  }
}
