import React from 'react'
import PropType from 'prop-types'
import { isEqual } from 'lodash'

import { QS_CONSTANTS } from '../../constants'
import { findOptionsInVocabulary, getOptionLabelAndValue } from '../../utils'

const RaidoInput = (props) => {
  const {
    questionSet,
    vocabularies,
    question,
    value,
    textValue,
    path,
    disabled,
    Component,
    Data
  } = props
  const variable = question.variable
  const optionsID = Data.getOption(question, path)
  const options = findOptionsInVocabulary(optionsID, vocabularies).map((option) =>
    getOptionLabelAndValue(option)
  )

  const onOptionChange = (value) =>
    Data.handleRadioSelect(
      value,
      optionsID,
      variable,
      questionSet.name,
      QS_CONSTANTS.INPUT_TYPE.RADIO,
      questionSet,
      question,
      path
    )
  const onTextChange = (e) =>
    Data.handleChange(
      e,
      `${variable}-other`,
      questionSet.name,
      QS_CONSTANTS.INPUT_TYPE.TEXT,
      questionSet,
      question,
      path
    )
  return (
    <>
      <Component
        CREATE_VALUE={QS_CONSTANTS.CREATE_VALUE}
        disabled={disabled}
        onOptionChange={onOptionChange}
        onTextChange={onTextChange}
        options={options}
        textValue={textValue}
        value={value}
        variable={variable}
      />
    </>
  )
}

RaidoInput.propTypes = {
  Component: PropType.oneOfType([PropType.func, PropType.object]),
  Data: PropType.object,
  disabled: PropType.bool,
  path: PropType.array,
  question: PropType.object,
  questionSet: PropType.object,
  vocabularies: PropType.array,
  textValue: PropType.object,
  value: PropType.object
}

const areEqual = (prevProps, props) => {
  const areEqual =
    isEqual(prevProps.value, props.value) &&
    !!prevProps.disable === !!props.disable &&
    isEqual(prevProps.textValue, props.textValue) &&
    isEqual(prevProps.vocabularies, props.vocabularies)
  return areEqual
}

export default React.memo(RaidoInput, areEqual)
